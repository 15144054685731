import {Component} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {Router} from '@angular/router';
import {AddEditModeEnum} from '../../../enums/add-edit-form.enum';
import {ComponentTypeEnum} from '../../../enums/component-types.enum';

@Component({
  selector: 'app-role-cell-renderer',
  templateUrl: './role-cell-renderer.component.html',
  styleUrls: ['./role-cell-renderer.component.scss']
})
export class RoleCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  roleInfoDialog: MatDialogRef<any>;
  parentComponentType: ComponentTypeEnum = ComponentTypeEnum.ANY;
  protected readonly componentTypeEnum = ComponentTypeEnum;

  constructor(private matDialog: MatDialog, private router: Router) { }

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.setParentComponentType(params);
  }

  setParentComponentType(params: ICellRendererParams){
    switch(params?.context?.this?.componentType) {
      case ComponentTypeEnum.APP_ROLES: {
        this.parentComponentType = ComponentTypeEnum.APP_ROLES;
        break;
      }
      case ComponentTypeEnum.USER_APP_ACCESS_ROLE_GRID: {
        this.parentComponentType = ComponentTypeEnum.USER_APP_ACCESS_ROLE_GRID;
        break;
      }
      default: {
        this.parentComponentType = ComponentTypeEnum.ANY;
        break;
      }
    }
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  openRoleDetailsModal() {
    this.roleInfoDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: this.params.value,
        body:
          '<table>'
          + '  <tr>'
          + '    <th class="role-id-header">Role ID</th>'
          + '    <th class="role-desc-header">Role Description</th>'
          + '  </tr>'
          + '  <tr>'
          + '    <td>'+ this.params.data.roleCode + '</td>'
          + '    <td>'+ this.params.data.roleDescription + '</td>'
          + '  </tr>'
          + '</table>',
        actionLabel: 'Edit Role',
        cancelLabel: 'Close',
        noCloseX: false,
      },
      panelClass: 'role-info-modal'
    });

    this.roleInfoDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.router.navigate(['/app-management/manage/' + this.params.context.this.selectedApp.appCode + '/role'], {
        state: {
          data: this.params.data,
          mode: AddEditModeEnum.EDIT
        }
      });
    });
  }
}
