import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PBMAppInfo, PBMRoleInfo} from '../../../../models/AppInfo';
import {AppDataService} from '../../../../services/app-management/data/app-data.service';
import {Router} from '@angular/router';
import {FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  alreadyExistsValidator,
  duplicateRoleExclusionValidator,
  isRequiredValidator,
  roleExclusionValidator,
  selfRoleExclusionValidator,
  validCharactersValidator
} from '../../../../validator/validator.utils';
import {AddEditModeEnum, AddRoleFormGroup, StatusCodes} from '../../../../enums/add-edit-form.enum';
import {AppManagementService} from '../../../../services/app-management/app-management.service';
import {CellClickedEvent, ColumnApi, GridApi, GridReadyEvent, RowNode} from 'ag-grid-community';
import {Chip, CVSBannerType} from 'angular-component-library';
import {BannerLinkHelperService} from '../../../../services/bannerlink-helper/banner-link-helper.service';
import {AgGridHelper} from '../../../../ag-grid-utils/helpers/AgGridHelper';
import {HttpErrorResponse} from '@angular/common/http';
import {getErrorMessageOnAddOrEdit} from '../../../../utils/getErrorMessageOnAddOrEdit';
import {SearchComponent} from '../../../../ag-grid-utils/search/search.component';
import {
  ExpandCollapseButtonsComponent
} from '../../../../ag-grid-utils/expand-collapse-buttons/expand-collapse-buttons.component';
import {PBMPermissionInfo} from '../../../../models/PBMPermissionInfo';
import {MatLegacyTabChangeEvent as MatTabChangeEvent} from '@angular/material/legacy-tabs';
import {UserAccessType} from '../../../../enums/add-user-form.enum';
import {FormUtils} from '../../../../utils/form-utils/formUtils';
import {BannerService} from '../../../../services/banner-service/banner.service';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss']
})
export class AddEditRoleComponent implements OnInit {
  protected readonly UserAccessType = UserAccessType;
  selectedApp: PBMAppInfo;
  roleModeEnum = AddEditModeEnum;
  mode: AddEditModeEnum;
  addEditRoleForm: UntypedFormGroup;
  index = 0;
  showSelectedPermissions = false;
  statusOptions = [
    [StatusCodes.PENDING, StatusCodes.ACTIVE, StatusCodes.INACTIVE]
  ];
  statusOption = this.statusOptions[this.index];
  context = {this: this};
  gridApi: GridApi;
  columnApi: ColumnApi;
  appRoleCodes: string[];
  excludedRoleChips: Array<Chip>;

  formSubmitted = false;

  editRoleData: PBMRoleInfo;
  permissionsCopied: PBMPermissionInfo[];

  @ViewChild('searchCapabilitiesComponent') searchComponent: SearchComponent;
  @ViewChild('expandCollapseButtonsComponent') expandCollapseButtonsComponent: ExpandCollapseButtonsComponent;

  fieldsIncludedInSearch: string[] = ['capabilityName', 'permissionDescription', 'name', 'permissionCode'];

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    suppressMenu: true,
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  autoGroupColumnDef = {
    headerName: 'App Capability',
    field: 'capabilityName',
    minWidth: 220,
    cellRenderer: 'agGroupCellRenderer',
    headerCheckboxSelection: true,
    showDisabledCheckboxes: true,
    cellRendererParams: {
      suppressCount: true,
      checkbox: (params) => {
        return !params.context.this.showSelectedPermissions;
      },
      innerRenderer: (params) => {
        if (params.node.group) {
          return params.value;
        }
      },
    }
  };

  columnDefs = [
    {
      headerName: 'App Capability',
      field: 'capabilityName',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Permission ID',
      field: 'permissionCode',
    },
    {
      headerName: 'Permission Name',
      field: 'name',
    },
    {
      headerName: 'Permission Description',
      field: 'permissionDescription',
      width: 150,
      flex: 2,
    }
  ];

  formControlNameToDisplayNameMap: Map<string, string> = new Map([
    [AddRoleFormGroup.ROLE_ACCESS_TYPES, 'User Access Type'],
    [AddRoleFormGroup.ROLE_NAME, 'Role Name'],
    [AddRoleFormGroup.ROLE_ID, 'Role ID'],
    [AddRoleFormGroup.ROLE_DESCRIPTION, 'Role Description'],
    [AddRoleFormGroup.EXCLUDED_ROLES, 'Excluded Roles']
  ]);

  errorCodeOnAddToBannerDataMap: Map<number, any> = new Map([
    [-1, {
      headline: 'Failed to add Role',
      body: 'Please try again or enter alternative Role details.'
    }],
    [-2, {
      headline: 'Failed to update Role',
      body: 'Please try again or enter alternative Role details.'
    }]
  ]);

  constructor(
    private appDataService: AppDataService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private appManagementService: AppManagementService,
    private bannerService: BannerService,
    private bannerLinkHelperService: BannerLinkHelperService,
    private el: ElementRef,
  ) {
    this.appDataService.currentApp$.subscribe((app: PBMAppInfo) => this.selectedApp = app);

    if (this.router.getCurrentNavigation().extras.state === undefined) {
      this.router.navigate(['/']).then();
    }

    this.mode = this.router.getCurrentNavigation().extras.state.mode;

    this.appRoleCodes = this.selectedApp.roles?.map(role => role.roleCode);
    if (this.mode === AddEditModeEnum.EDIT) {
      this.editRoleData = this.mapRouterDataToRole(this.router.getCurrentNavigation().extras.state.data);
      this.appRoleCodes.filter(roleCode => roleCode !== this.editRoleData.roleCode);
      this.excludedRoleChips = this.getExcludedRoleChips();
    }

    if (this.mode === AddEditModeEnum.ADD && this.router.getCurrentNavigation().extras.state.data) {
      this.permissionsCopied = this.router.getCurrentNavigation().extras.state.data;
    }
  }

  private getExcludedRoleChips() {
    return this.editRoleData.excludedRoles?.map(excludedRole => (
        {
          name: excludedRole.roleCode,
          removable: true
        }
      ));
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.addEditRoleForm = this.formBuilder.group({
      roleAccessTypes: FormUtils.createAccessTypeFormGroup(this.formBuilder),
      roleName: ['', [
        isRequiredValidator(),
        validCharactersValidator(),
        alreadyExistsValidator(this.selectedApp.roles, this.editRoleData?.roleName),
      ]],
      roleId: [''],
      roleDescription: ['', [
        isRequiredValidator()
      ]],
      roleStatus: ['', Validators.required],
      adminType: [null],
      excludedRoles: this.formBuilder.array([], [
        roleExclusionValidator(this.selectedApp.roles),
        duplicateRoleExclusionValidator(),
        selfRoleExclusionValidator(this.editRoleData)
      ])
    });
  }

  private mapRouterDataToRole(stateData: any): PBMRoleInfo {
    return {
      id: stateData.id,
      appId: stateData.appId,
      roleName: stateData.roleName,
      roleCode: stateData.roleCode,
      roleDescription: stateData.roleDescription,
      status: stateData.status,
      adminType: stateData.adminType,
      accessTypes: stateData.accessTypes,
      permissions: stateData.permissions,
      roleRank: stateData.roleRank,
      excludedRoles: stateData.excludedRoles
    } as PBMRoleInfo;
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.gridApi.setRowData(this.selectedApp.permissions?.filter(permission => permission.status === StatusCodes.ACTIVE));

    if (this.mode === AddEditModeEnum.EDIT) {
      this.populateDataForEdit();
    }

    if (this.mode === AddEditModeEnum.ADD && this.permissionsCopied) {
      this.copyPermissionAndSetExpanded();
    }
  }

  populateDataForEdit() {
    this.roleName.setValue(this.editRoleData.roleName);
    this.roleId.setValue(this.editRoleData.roleCode);
    this.roleDescription.setValue(this.editRoleData.roleDescription == null ? '' : this.editRoleData.roleDescription);
    this.roleStatus.setValue(this.editRoleData.status);
    this.editRoleData.permissions.forEach(permission => {
      const rowNode = this.gridApi.getRowNode(permission.id.toString());
      if (rowNode != null) {
        rowNode.setSelected(true);
      } else {
        this.gridApi.applyTransaction({add: [permission]});
        this.gridApi.getRowNode(permission.id.toString())?.setSelected(true);
      }
    });
    this.editRoleData.accessTypes.forEach(existingAccessType => {
      this.roleAccessTypes.get(existingAccessType.accessType).setValue(true);
    });
    this.adminType.setValue(this.editRoleData.adminType);
    this.editRoleData.excludedRoles.forEach(excludedRole => {
      this.excludedRoles.push(new FormControl(excludedRole.roleCode));
    });
  }

  copyPermissionAndSetExpanded() {
    this.permissionsCopied.forEach(permission => {
      const rowNode = this.gridApi.getRowNode(permission.id.toString());
      rowNode?.setSelected(true);
      rowNode?.parent?.setExpanded(true);
    });
  }

  onSubmitForm() {
    this.formSubmitted = true;
    this.roleStatus.setValue(this.roleStatus.value ? this.roleStatus.value : StatusCodes.PENDING);
    if (this.addEditRoleForm.valid) {
      const pbmRoleInfo: PBMRoleInfo = {
        id: (this.mode === AddEditModeEnum.ADD ? null : this.editRoleData.id),
        appId: this.selectedApp.id,
        roleDescription: this.roleDescription.value,
        status: this.roleStatus.value,
        roleName: this.roleName.value,
        roleCode: this.roleId.value,
        roleRank: 4, // TODO: change roleRank to not 4
        permissions: this.gridApi.getSelectedRows(),
        adminType: this.adminType.value,
        accessTypes: FormUtils.getAccessTypes(this.roleAccessTypes),
        excludedRoles: this.getRoleInfos(this.excludedRoles.value)
      };

      switch (this.mode) {
        case AddEditModeEnum.ADD: {
          this.appManagementService.createAppRole(pbmRoleInfo, this.selectedApp.appCode).subscribe({
            next: this.handleAddEditSuccess.bind(this),
            error: this.handleAddEditFailure.bind(this)
          });
          break;
        }

        case AddEditModeEnum.EDIT: {
          this.appManagementService.updateAppRole(pbmRoleInfo).subscribe({
            next: this.handleAddEditSuccess.bind(this),
            error: this.handleAddEditFailure.bind(this)
          });
        }
      }
    } else {
      if (this.isDuplicateRoleName(this.selectedApp.roles) &&
        this.addEditRoleForm.controls['roleName'].errors &&
        FormUtils.getFormValidationErrors(this.addEditRoleForm).length === 1) {
        const errorBannerDataForDuplicateRoleName = {
          hideX: true,
          outletId: '#addEditRoleBanner',
          headline: 'Role Name Already Exists',
          body: 'A Role with the same name already exists. Please try again or enter an alternative Role Name'
        };

        this.bannerService.showValidationErrorBanner(errorBannerDataForDuplicateRoleName, CVSBannerType.Error);
      } else {
        const errorBannerData = {
          hideX: true,
          outletId: '#addEditRoleBanner',
          headline: 'Required Information Needed',
          body: 'Provide the following required information in order to proceed with ' +
            (this.mode === AddEditModeEnum.ADD ? 'adding a' : 'editing the') + ' role',
          bannerLinks: this.bannerLinkHelperService.createInvalidFieldFocusLinks(
            this.addEditRoleForm,
            this.formControlNameToDisplayNameMap,
            this.el)
        };

        this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
      }
    }
  }

  shouldStyleErrorForRoleAccessType() {
    return !this.addEditRoleForm.controls[AddRoleFormGroup.ROLE_ACCESS_TYPES].valid && this.formSubmitted;
  }

  canDeactivate(): boolean {
    return !this.addEditRoleForm.dirty;
  }

  exitAddEditRole() {
    this.router.navigate(['/app-management/manage/' + this.selectedApp.appCode]);
  }

  handleAddEditSuccess(response: PBMRoleInfo) {
    this.addEditRoleForm.markAsPristine();
    this.formSubmitted = false;

    const headLineText = this.mode === AddEditModeEnum.ADD ?
      `${response.roleName} has been added` :
      `${response.roleName} has been updated`;

    const bodyText = this.mode === AddEditModeEnum.ADD ?
      `The role ${response.roleName} has been successfully added to ${this.selectedApp.appName} and pending approval.` :
      `The role ${response.roleName} has been successfully updated.`;

    this.appManagementService.appManagementNotification.next({
      headLine: headLineText,
      body: bodyText,
      removedAfterMilliseconds: 5000
    });

    this.router.navigate(['/app-management/manage/' + this.selectedApp.appCode]);
  }

  handleAddEditFailure(error: HttpErrorResponse) {
    const errorBannerData = {
      hideX: true,
      outletId: '#addEditRoleBanner',
      headline: getErrorMessageOnAddOrEdit(this.errorCodeOnAddToBannerDataMap, error, this.mode).headline,
      body: getErrorMessageOnAddOrEdit(this.errorCodeOnAddToBannerDataMap, error, this.mode).body,
    };
    this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
  }

  isExternalFilterPresent(): boolean {
    if (this.context.this.searchComponent?.searchValue || this.context.this.showSelectedPermissions) {
      return true;
    }
  }

  doesExternalFilterPass(node: RowNode) {
    let combinedFilter = true;
    if (this.context.this.searchComponent?.searchValue) {
      combinedFilter = combinedFilter && AgGridHelper.doesExternalFilterPass(node,
        this.context.this.searchComponent.searchValue,
        this.context.this.fieldsIncludedInSearch);
    }
    if (this.context.this.showSelectedPermissions) {
      combinedFilter = combinedFilter && node.isSelected();
    }
    return combinedFilter;
  }

  updateAllRowsExpandedState() {
    this.expandCollapseButtonsComponent.updateExpandCollapseButtons();
  }

  isRowSelectable = (params) => {
    return !this.showSelectedPermissions;
  };

  onTabChanged(event: MatTabChangeEvent) {
    this.showSelectedPermissions = event.tab.textLabel === 'Selected Permissions';

    this.gridApi.onFilterChanged();
    this.autoGroupColumnDef.headerCheckboxSelection = !this.showSelectedPermissions;
    this.gridApi.setAutoGroupColumnDef(this.autoGroupColumnDef);

    this.expandCollapseButtonsComponent.updateExpandCollapseButtons();
  }

  toggleSelection(event: CellClickedEvent) {
    if (!this.showSelectedPermissions) {
      event.node.setSelected(!event.node.isSelected());
    }
  }

  isDuplicateRoleName(roles: PBMRoleInfo[]) {
    return roles.map(role => role.roleName.toLowerCase()).includes(this.roleName.value.toLowerCase());
  }

  private toggleInvalidStyling() {
    if (this.excludedRoles.valid) {
      document.querySelector('cvs-chip-input mat-form-field').classList.remove('mat-form-field-invalid');
    } else {
      document.querySelector('cvs-chip-input mat-form-field').classList.add('mat-form-field-invalid');
    }
  }

  onChipAdded(event: string): void {
    this.excludedRoles.push(new FormControl(event.toUpperCase()));
    this.excludedRoles.updateValueAndValidity();
    this.toggleInvalidStyling();
  }

  onChipRemoved(): void {
    this.excludedRoles.removeAt(this.excludedRoles.controls.findIndex(control=> control.invalid));
    this.excludedRoles.updateValueAndValidity();
    this.toggleInvalidStyling();
  }

  getRowId(params) {
    return params.data.id;
  }

  getRoleInfos(roleCodes: string[]): PBMRoleInfo[] {
    return this.selectedApp.roles.filter(role => roleCodes.includes(role.roleCode));
  }

  get roleName() {
    return this.addEditRoleForm.get('roleName');
  }

  get roleId() {
    return this.addEditRoleForm.get('roleId');
  }

  get roleDescription() {
    return this.addEditRoleForm.get('roleDescription');
  }

  get roleStatus() {
    return this.addEditRoleForm.get('roleStatus');
  }

  get adminType() {
    return this.addEditRoleForm.get('adminType');
  }

  get roleAccessTypes() {
    return this.addEditRoleForm.get('roleAccessTypes') as UntypedFormGroup;
  }

  get excludedRoles() {
    return this.addEditRoleForm.get('excludedRoles') as UntypedFormArray;
  }

  defaultEnumOrder = (): number => {
    return 0;
  };
}
