<cvs-loader-overlay-container [isLoading]="(showSpinner | async) "
                              [opacity]=".7"
                              loadingMessage="Exporting">
  <div style="width:100%;height:100%">
    <div id="clientReportBanner"></div>
    <h1 [tabIndex]="0" aria-label="Client Reports" class="header-padding">Client Reports</h1>
    <div class="tile__background-border">
      <div>
        <h2 class="header-padding">Generate Report</h2>
        <p class="report-management-description">
          Client reports may be generated based on client selection. Select/enter a search criteria, select the client from
          the search results, then click the Export Report button to obtain detailed client information.
        </p>
      </div>
      <form [formGroup]="clientReportFormGroup" (ngSubmit)="onExport()">
        <app-client-selector #clientSelector
          [isAllClientOptionShown]="true"
          [clientReportOption]="clientOption"
          (clientSelected)="onClientSelected($event)"
          (clientDropDownChanged)="onClientDropDownChange()">
        </app-client-selector>

        <div class="client-report-buttons-container">
          <button aria-label="Export Report Button" class="pds-btn-primary" type="submit">Export Report</button>
          <button aria-label ="Cancel Button" class="pds-btn-ghost" *ngIf="clientReportFormGroup.dirty"  (click)="openCancelModalForClientReport()" type="button">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</cvs-loader-overlay-container>
