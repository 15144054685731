import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ClientSearchType} from '../../enums/client-search-type.enum';
import {
  accountId,
  accountIdWithCheckbox,
  accountLevel,
  accountName,
  accountNameWithCheckbox,
  carrierId,
  carrierIdWithCheckbox,
  carrierName,
  carrierNameWithCheckbox,
  clientCode,
  clientCodeWithCheckbox,
  clientNameRdcAccount,
  clientNameRdcLevel,
  clientNameWithCheckbox,
  clientProfileForUserClientAccess,
  fromDate,
  marketSegment,
  rdcLevel,
  statusWithFilter,
  superClientId,
  superClientIdWithCheckbox,
  superClientName,
  superClientNameWithCheckbox,
  superClientRdcAccountWithFilter,
  thruDate
} from '../../models/ClientHierarchyColumns';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowModelType
} from 'ag-grid-community';
import {ClientManagementService} from '../../services/client-management/client-management.service';
import {HttpErrorResponse} from '@angular/common/http';
import {PBMUserInfo} from '../../models/User';
import {SuperClientInfo} from '../../models/SuperClientInfo';
import {AbstractControl, FormGroup, UntypedFormArray, UntypedFormBuilder,} from '@angular/forms';
import {AddUserFormGroup, ClientAccessFromGroup} from '../../enums/add-user-form.enum';
import {ClientHierarchyEnum} from '../../enums/client-hierarchy-enum';
import {MatExpansionPanel} from '@angular/material/expansion';
import {MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';
import {ClientDTO} from '../../models/ClientDTO';
import {ClientProfileModalService} from '../../services/client-profile/modal/client-profile-modal.service';

@Component({
  selector: 'app-user-client-access',
  templateUrl: './user-client-access.component.html',
  styleUrls: ['./user-client-access.component.scss']
})
export class UserClientAccessComponent implements OnInit {
  @Input() userBeingEditedOrCopied: PBMUserInfo;
  @Input() gridInputData: SuperClientInfo[];
  @Input() addUserForm: FormGroup;

  @ViewChild('superClientExpansionPanel') superClientExpansionPanel: MatExpansionPanel;
  @ViewChild('clientCodeExpansionPanel') clientCodeExpansionPanel: MatExpansionPanel;
  @ViewChild('carrierExpansionPanel') carrierExpansionPanel: MatExpansionPanel;
  @ViewChild('clientHierarchyExpansionPanel') clientHierarchyExpansionPanel: MatExpansionPanel;

  @ViewChildren('lobSelectAllCheckBox') private lobSelectAllCheckBoxes: QueryList<MatCheckbox>;

  clientHierarchy: ClientDTO[] = [];
  clientUniqueIdSet: Set<string> = new Set<string>();

  superClients: ClientDTO[] = [];
  superClientIdSet: Set<number> = new Set<number>();

  clientCodes: ClientDTO[] = [];
  clientCodeIdSet: Set<number> = new Set<number>();

  carriers: ClientDTO[] = [];
  carrierIdSet: Set<number> = new Set<number>();

  lobs: string[] = [
    'Commercial',
    'Dual Demo',
    'Dual/MMP (Medicaid/Medicare Plan)',
    'EGWP (Employer Group Waiver Plan)',
    'Exchange',
    'Medicaid',
    'Medicare Part B',
    'Medicare Part D',
    'TPA (Third Party Admin)'
  ];

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: false,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset', 'apply'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };


  superClientIdColumnDefs = [
    superClientIdWithCheckbox,
    superClientName,
    clientProfileForUserClientAccess,
    accountId,
    accountName,
    accountLevel,
    statusWithFilter,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  superClientNameColumnDefs = [
    superClientNameWithCheckbox,
    superClientId,
    clientProfileForUserClientAccess,
    accountId,
    accountName,
    accountLevel,
    statusWithFilter,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  sfdcAccountIdColumnDefs = [
    accountIdWithCheckbox,
    accountName,
    clientProfileForUserClientAccess,
    superClientId,
    superClientName,
    statusWithFilter,
    accountLevel,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  sfdcAccountNameColumnDefs = [
    accountNameWithCheckbox,
    accountId,
    clientProfileForUserClientAccess,
    accountLevel,
    statusWithFilter,
    superClientId,
    superClientName,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  clientCodeColumnDefs = [
    clientCodeWithCheckbox,
    clientProfileForUserClientAccess
  ];

  clientNameColumnDefs: ColDef[] = [
    clientNameWithCheckbox,
    clientNameRdcAccount,
    clientNameRdcLevel,
  ];

  carrierIdColumnDefs = [
    carrierIdWithCheckbox,
    carrierName,
    clientProfileForUserClientAccess,
    fromDate,
    thruDate,
    clientCode,
    statusWithFilter,
    superClientRdcAccountWithFilter,
    rdcLevel
  ];

  carrierNameColumnDefs = [
    carrierNameWithCheckbox,
    carrierId,
    clientProfileForUserClientAccess,
    fromDate,
    thruDate
  ];

  clientDropdownOptions = [
    {
      label: ClientSearchType.SUPER_CLIENT_ID,
      colDef: this.superClientIdColumnDefs,
      searchParam: 'superClientId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SUPER_CLIENT_NAME,
      colDef: this.superClientNameColumnDefs,
      searchParam: 'superClientName',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.CLIENT_CODE,
      colDef: this.clientCodeColumnDefs,
      searchParam: 'clientCode',
      accountLevel: ClientHierarchyEnum.CLIENT_CODE
    },
    {
      label: ClientSearchType.CLIENT_NAME,
      colDef: this.clientNameColumnDefs,
      searchParam: 'clientName',
      accountLevel: null
    },
    {
      label: ClientSearchType.CARRIER_ID,
      colDef: this.carrierIdColumnDefs,
      searchParam: 'carrierId',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.CARRIER_NAME,
      colDef: this.carrierNameColumnDefs,
      searchParam: 'carrierName',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_ID,
      colDef: this.sfdcAccountIdColumnDefs,
      searchParam: 'accountId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_NAME,
      colDef: this.sfdcAccountNameColumnDefs,
      searchParam: 'accountName',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    }
  ];

  clientDropdownOption = this.clientDropdownOptions[0];
  clientSearchType: ClientSearchType = this.clientDropdownOption.label;
  showErrorResponse;
  showGrid;
  context = {this: this};
  rowModelType: RowModelType = 'serverSide';
  isGridReady = false;
  gridApi: GridApi;
  columnApi: ColumnApi;
  defaultPaginatorSize = 50;

  constructor(
    private clientManagementService: ClientManagementService,
    private formBuilder: UntypedFormBuilder,
    private clientProfileModalService: ClientProfileModalService
  ) { }

  ngOnInit(): void {
    Promise.resolve(null).then(() => this.getUserClientAccess());
    this.addUserForm.get(AddUserFormGroup.LOCATION).valueChanges.subscribe(value => {
      this.onUserLocationChange();
    });
  }

  onUserLocationChange() {
    if (this.clientDropdownOption.label === ClientSearchType.CLIENT_NAME && this.isGridReady) {
      this.gridApi.redrawRows();
      this.addDisabledAttributeToCheckbox();
    }
  }

  getUserClientAccess() {
    if (this.userBeingEditedOrCopied) {

      const userClientHierarchy = this.userBeingEditedOrCopied.assignedPortalClients.filter(client => {
        return client.id === undefined;
      });
      this.clientHierarchy = userClientHierarchy;
      userClientHierarchy.forEach(client => {
        this.userClientAccess.push(this.formBuilder.control(client));
        this.clientUniqueIdSet.add(client.clientUniqueId);
      });

      const userSuperClients = this.userBeingEditedOrCopied.assignedPortalClients.filter(client => {
        return client.accountLevel === ClientHierarchyEnum.SUPER_CLIENT;
      });

      userSuperClients.forEach(assignedClient => {
        const clientFormGroup = {
          client: assignedClient,
          lineOfBusinesses: [assignedClient.lineOfBusinesses],
          clientSelected: true,
        };
        this.userClientAccessWithLob.push(this.formBuilder.group(clientFormGroup));
        this.superClientIdSet.add(assignedClient.id);
      });

      const userClientCodes = this.userBeingEditedOrCopied.assignedPortalClients.filter(client => {
        return client.accountLevel === ClientHierarchyEnum.CLIENT_CODE;
      });
      this.clientCodes = userClientCodes;
      userClientCodes.forEach(client => {
        this.userClientAccess.push(this.formBuilder.control(client));
        this.clientCodeIdSet.add(client.id);
      });
      const userCarrierAccess = this.userBeingEditedOrCopied.assignedPortalClients.filter(client => {
        return client.accountLevel === ClientHierarchyEnum.CARRIER;
      });

      this.carriers = userCarrierAccess;
      userCarrierAccess.forEach(client => {
        this.userClientAccess.push(this.formBuilder.control(client));
        this.carrierIdSet.add(client.id);
      });
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.isGridReady = true;
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    AgGridHelper.insertRowsPerPageSelector();
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
    this.createServerSideData();
  }

  onDropDownChange() {
    this.clientSearchType = this.clientDropdownOption.label;
  }

  getClientManagementData(searchValue) {
    this.clientManagementService.globalSearchValue = searchValue;
    this.showGrid = true;
    this.showErrorResponse = false;

    if (this.isGridReady) {
      this.createServerSideData();
    }
  }

  onClientSearchValidationError() {
    this.showErrorResponse = false;
  }

  createServerSideData() {
    const datasource: IServerSideDatasource = this.createServerSideDatasource();
    this.gridApi.setServerSideDatasource(datasource);
  }

  createServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.gridApi.deselectAll();
        this.gridApi.setColumnDefs(this.clientDropdownOption.colDef);
        this.gridApi.refreshHeader();
        if (this.clientDropdownOption.label === ClientSearchType.CLIENT_NAME) {
          this.clientManagementService.searchClientsByName(
            (params.request.startRow/(params.request.endRow - params.request.startRow)) + 1,
                 params.request.endRow - params.request.startRow).subscribe({
            next: this.handleGetClientDataSuccess.bind(this, params),
            error: this.handleGetClientDataFailure.bind(this,params)
          });
          return;
        }
        this.clientManagementService.searchClients(
                    this.clientDropdownOption.accountLevel,
                    this.clientDropdownOption.searchParam,
                    params.request.startRow,
                    params.request.endRow,
                    params.request.filterModel).subscribe({
            next: this.handleGetClientDataSuccess.bind(this, params),
            error: this.handleGetClientDataFailure.bind(this, params)
          });
      }
    };
  }

  private handleGetClientDataSuccess(params: IServerSideGetRowsParams, response: any) {
    params.success({
      rowData: response.data,
      rowCount: response.count
    });
    this.addDisabledAttributeToCheckbox();
  }

  private handleGetClientDataFailure(params: IServerSideGetRowsParams, err: HttpErrorResponse) {
    params.fail();
    this.showErrorResponse = true;
    this.showGrid = false;
  }

  onRowSelected(event) {
    this.addUserForm.markAsDirty();
    if (event.node.selected) {
      switch (event.data.accountLevel) {
        case ClientHierarchyEnum.SUPER_CLIENT:
          this.addClientWithLobToUser(event.data, this.superClients, this.superClientIdSet);
          this.superClientExpansionPanel.expanded = true;
          break;
        case ClientHierarchyEnum.CLIENT_CODE:
          this.addClientToUser(event.data, this.clientCodes, this.clientCodeIdSet);
          this.clientCodeExpansionPanel.expanded = true;
          break;
        case ClientHierarchyEnum.CARRIER:
          this.addClientToUser(event.data, this.carriers, this.carrierIdSet);
          this.carrierExpansionPanel.expanded = true;
          break;
        default:
          if (event.data.rdcLevel === 'All' &&
              this.addUserForm.get(AddUserFormGroup.LOCATION).value === 'Offshore') {
            event.node.setSelected(false);
            return;
          }
          this.addClientToUser(event.data, this.clientHierarchy, this.clientUniqueIdSet);
          this.clientHierarchyExpansionPanel.expanded = true;
          break;
      }
    }
  }

  addClientWithLobToUser(rowData, clientList: ClientDTO[], idSet: Set<number>) {
    if (!idSet.has(rowData.id)) {
      const clientLobFromGroup = {
        client: rowData,
        lineOfBusinesses: [],
        clientSelected: true
      };
      this.userClientAccessWithLob.push(this.formBuilder.group(clientLobFromGroup));
      clientList.push(rowData);
      idSet.add(rowData.id);
    }
  }

  addClientToUser(rowData: ClientDTO, clientList: ClientDTO[], idSet: Set<number|string>) {
    if (rowData.id === undefined) {
      if (!idSet.has(rowData.clientUniqueId)) {
        this.userClientAccess.push(this.formBuilder.control(rowData));
        clientList.push(rowData);
        idSet.add(rowData.clientUniqueId);
      }
      return;
    }
    if (!idSet.has(rowData.id)) {
      this.userClientAccess.push(this.formBuilder.control(rowData));
      clientList.push(rowData);
      idSet.add(rowData.id);
    }
  }

  removeClientFromUser(rowData: any) {
    const userClientAccess = this.userClientAccess;
    userClientAccess.removeAt(userClientAccess.controls.findIndex(control => control.value === rowData));

    this.deselectClientNodeFromGrid(rowData);

    if (this.clientUniqueIdSet.has(rowData.clientUniqueId) && rowData.id === undefined) {
      this.clientUniqueIdSet.delete(rowData.clientUniqueId);
      this.clientHierarchy = this.clientHierarchy.filter(clientDTO => clientDTO.clientUniqueId !== rowData.clientUniqueId);
    }
  }

  deselectClientNodeFromGrid(rowData: any) {
    const isClientSelectedInGrid = this.gridApi?.getSelectedRows().find(
      selectedRow => selectedRow.clientUniqueId === rowData.clientUniqueId);

    if (isClientSelectedInGrid) {
      this.gridApi?.forEachNode(node => {
        if (node.data.clientUniqueId === rowData.clientUniqueId) {
          node.setSelected(false);
        }
      });
    }
  }

  clientCheckboxChange(event: MatCheckboxChange) {
    this.addUserForm.markAsDirty();
    if (event.checked) {
      this.userClientAccess.push(this.formBuilder.control(event.source.value));
    } else {
      this.removeClientFromUser(event.source.value);
    }
  }

  showMoreThanOneClientSelectedError(accountLevel: ClientHierarchyEnum): boolean {
    const assignedClients = this.userClientAccess.controls?.find(control =>
      control.value.accountLevel === accountLevel);
    return !!assignedClients && !!this.userClientAccess.errors?.moreThanOneClientAssigned;
  }

  showMoreThanOneClientSelectedForSuperClientLevel(): boolean {
    const assignedSuperClients = this.userClientAccessWithLob.controls?.find(control =>
      control.value?.clientSelected === true);
    return !!assignedSuperClients && !!this.userClientAccess.errors?.moreThanOneClientAssigned;
  }

  openClientProfileModal(client: any, isFromCarrier?: any) {
    this.clientProfileModalService.openClientProfileModal(client, isFromCarrier);
  }

  toggleOption(lobsFormControl: AbstractControl, index: number) {
    const lobSelectAllCheckBox = this.lobSelectAllCheckBoxes.get(index);
    if ((lobSelectAllCheckBox.checked) && (lobsFormControl.value.length < this.lobs.length)) {
      lobSelectAllCheckBox.toggle();
    }
    if ((!lobSelectAllCheckBox.checked) && (lobsFormControl.value.length === this.lobs.length)) {
      lobSelectAllCheckBox.toggle();
    }
  }

  toggleAllSelection(lobsFormControl: AbstractControl, index: number) {
    this.addUserForm.markAsDirty();
    const lobSelectAllCheckBox = this.lobSelectAllCheckBoxes.get(index);
    if (lobSelectAllCheckBox.checked) {
      lobsFormControl.patchValue([]);
    } else {
      lobsFormControl.patchValue([...this.lobs]);
    }
    lobSelectAllCheckBox.toggle();
  }

  isIndeterminate(lobsFormControl: AbstractControl) {
    return lobsFormControl.value && lobsFormControl.value.length > 0 &&
      lobsFormControl.value.length < this.lobs.length;
  }

  lobSelectAllChecked(lobsFormControl: AbstractControl) {
    return lobsFormControl.value && lobsFormControl.value.length > 0 &&
      lobsFormControl.value.length === this.lobs.length;
  }

  get userClientAccess() {
    return this.addUserForm.get(AddUserFormGroup.USERS_CLIENT_ACCESS) as UntypedFormArray;
  }

  get userClientAccessWithLob() {
    return this.addUserForm.get(AddUserFormGroup.USERS_CLIENT_ACCESS_WITH_LOB) as UntypedFormArray;
  }

  protected readonly ClientHierarchyEnum = ClientHierarchyEnum;
  protected readonly ClientAccessFromGroup = ClientAccessFromGroup;

  rdcIsRowDisabled = (params) => {
    if(params.data?.rdcLevel === 'All' &&
      this.addUserForm.get(AddUserFormGroup.LOCATION).value === 'Offshore') {
      return 'rdc-disabled';
    }
  };

  // This is built in ag-grid functionality in v31 using isRowSelectable
  addDisabledAttributeToCheckbox() {
    document.querySelectorAll('.client-access-grid .ag-checkbox-input').forEach((checkBoxInput: HTMLInputElement) => {
      checkBoxInput.disabled = false;
    });
    document.querySelectorAll('.client-access-grid .rdc-disabled .ag-checkbox-input').forEach((checkBoxInput: HTMLInputElement) => {
      checkBoxInput.disabled = true;
    });
  }
}
