import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {CurrentUserService} from '../../services/current-user/current-user.service';
import {PBMUserInfo} from '../../models/User';
import {PageRoute} from '../../enums/page-route.enum';

@Injectable({
  providedIn: 'root'
})
export class ActiveGuard  {

  currentUser: PBMUserInfo;
  constructor(private currentUserService: CurrentUserService, private router: Router) {
    this.currentUserService.currentUser$.subscribe((user: PBMUserInfo) => this.currentUser = user);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verifyStatus();
  }

  verifyStatus() {
    const userActive =  this.currentUser?.basicUserInfo.active;

    if (userActive) {
      return true;
    }

    return this.router.parseUrl(PageRoute.NO_ACCESS_PAGE);
  }

}
