<cvs-loader-overlay-container [isLoading]="(showSpinner | async)" [opacity]="0.7" loadingMessage="Loading">
  <div class="app-overview-container" *ngIf="selectedApp">
    <form [formGroup]="addAppForm">
      <h2 style="padding-top: 15px">Summary</h2>

      <div class="label">App Name</div>
      <div>{{selectedApp.appName}}</div>

      <div class="label">App Code/Domain</div>
      <div>{{selectedApp.appCode}}/{{selectedApp.appDomain}}</div>

      <div class="label">App Description</div>
      <div>{{selectedApp.appDescription}}</div>

      <hr class="section-divider">

      <div class="header-tooltip-container">
        <h2 class="app-overview-bold-header">User Types</h2>
        <button
          role="tooltip"
          type="button"
          class="pds-icon-only-btn overview-tooltip-button"
          [cvsTooltip]="'Describes the types of users this app supports.'"
          [tooltip-class]="'overview-tooltip'"
          placement="right"
          [showArrow]="true"
          aria-label="Describes the types of users this app supports.">
          <mat-icon svgIcon="info-circle--s" role="img" aria-describedby="tool-tip"></mat-icon>
        </button>
      </div>

      <div class="user-type-checkbox-container">
        <ng-container formArrayName="appAccessTypes" *ngFor="let accessTypeControl of appAccessTypes.controls;let i = index">
          <ng-container [formGroup]="accessTypeControl">
            <mat-checkbox (change)="onAppAccessTypeChanged($event)" formControlName="{{accessTypeControl.get('accessType').value}}">{{controlNameToLabelMap[accessTypeControl.get('accessType').value]}}</mat-checkbox>
          </ng-container>
        </ng-container>
      </div>

      <div class="header-tooltip-container">
        <h3>Locations Supported</h3>
        <button
          role="tooltip"
          type="button"
          class="pds-icon-only-btn overview-tooltip-button"
          [cvsTooltip]="'Describes whether or not this app supports users that are located inside or outside the United States.'"
          [tooltip-class]="'overview-tooltip'"
          placement="right"
          [showArrow]="true"
          aria-label="Describes whether or not this app supports users that are located inside or outside the United States.">
          <mat-icon svgIcon="info-circle--s" role="img" aria-describedby="tool-tip"></mat-icon>
        </button>
      </div>

      <div class="user-type-checkbox-container">
        <ng-container formArrayName="appLocations" *ngFor="let locationControl of appLocations.controls;let i = index">
          <ng-container [formGroup]="locationControl">
            <mat-checkbox (change)="onAppLocationChanged($event)" formControlName="{{locationControl.get('location').value}}">{{controlNameToLabelMap[locationControl.get('location').value]}}</mat-checkbox>
          </ng-container>
        </ng-container>
      </div>

      <div class="header-tooltip-container">
        <h3>App Requires Client Provisioning for Users</h3>
        <button
          role="tooltip"
          type="button"
          class="pds-icon-only-btn overview-tooltip-button"
          [cvsTooltip]="'Describes whether or not this app requires users be assigned to access client data.'"
          [tooltip-class]="'overview-tooltip'"
          placement="right"
          [showArrow]="true"
          aria-label="Describes whether or not this app requires users be assigned to access client data.">
          <mat-icon svgIcon="info-circle--s" role="img" aria-describedby="tool-tip"></mat-icon>
        </button>
      </div>

      <div class="app-overview-toggle">
        <cvs-toggle-switch formControlName="clientProvisioningRequired"
                           [value]="clientProvisioningRequired.value"
                           [toggleValue]="clientProvisioningRequired.value ? 'Required' : 'Not Required'">
        </cvs-toggle-switch>
      </div>

      <hr class="section-divider">

      <div class="header-tooltip-container">
        <h3>Member Data Exposed</h3>
        <button
          role="tooltip"
          type="button"
          class="pds-icon-only-btn overview-tooltip-button"
          [cvsTooltip]="'Describes whether or not a client wants member data accessed by offshore users and if the member’s Personal Identifiable Information is concealed.'"
          [tooltip-class]="'overview-tooltip'"
          placement="right"
          [showArrow]="true"
          aria-label="Describes whether or not a client wants member data accessed by offshore users and if the member’s Personal Identifiable Information is concealed.">
          <mat-icon svgIcon="info-circle--s" role="img" aria-describedby="tool-tip"></mat-icon>
        </button>
      </div>

      <div class="app-overview-toggle">
        <cvs-toggle-switch formControlName="exposesMemberData"
                           [value]="exposesMemberData.value"
                           [toggleValue]="exposesMemberData.value ? 'Yes' : 'No'">
        </cvs-toggle-switch>
      </div>
      <mat-checkbox class="uses-masking-checkbox" formControlName="usesMasking">De-Identified</mat-checkbox>

      <hr class="section-divider" *ngIf="this.currentUser.adminUser">

      <div class="header-tooltip-container" *ngIf="this.currentUser.adminUser">
        <h2 class="app-overview-bold-header">Active</h2>
        <button
          role="tooltip"
          type="button"
          class="pds-icon-only-btn overview-tooltip-button"
          [cvsTooltip]="'Describes whether or not an application is in use in myPBM.'"
          [tooltip-class]="'overview-tooltip'"
          placement="right"
          [showArrow]="true"
          aria-label="Describes whether or not an application is in use in myPBM.">
          <mat-icon svgIcon="info-circle--s" role="img" aria-describedby="tool-tip"></mat-icon>
        </button>
      </div>

      <div class="app-overview-toggle" *ngIf="this.currentUser.adminUser">
        <cvs-toggle-switch formControlName="isActive"
                           [value]="isActive.value"
                           [toggleValue]="isActive.value ? 'Yes' : 'No'">
        </cvs-toggle-switch>
      </div>
      <div class="button-container">
        <button id="saveUserBtn" (click)="onSubmit()" class="pds-btn-primary">
          Save
        </button>
      <button class="pds-btn-ghost" (click)="openCancelConfirmationModal()" type="button">
        Cancel
      </button>
    </div>
    </form>
  </div>
</cvs-loader-overlay-container>
