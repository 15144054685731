import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {CurrentUserService} from '../../services/current-user/current-user.service';
import {PageRoute} from '../../enums/page-route.enum';
import {PBMUserInfo} from '../../models/User';

@Injectable({
  providedIn: 'root'
})
export class LandingPageGuard  {

  currentUser: PBMUserInfo;

  constructor(private currentUserService: CurrentUserService,
              private router: Router) {
    this.currentUserService.currentUser$.subscribe((user: PBMUserInfo) => this.currentUser = user);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    if (url && (url.startsWith(PageRoute.LANDING_PAGE))) {
      return this.verifyRoles();
    }
    if (url && url.startsWith(PageRoute.NO_ACCESS_PAGE)) {
      return !!this.currentUser;
    }
    return false;
  }

  verifyRoles() {
    const roles = this.currentUser?.appRoles as object[];
    if (this.currentUser?.appRoles && roles.length) {
        return true;
    }
    return this.router.parseUrl(PageRoute.NO_ACCESS_PAGE);
  }
}
