import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {
  CVSBannerComponentData,
  CVSBannerService,
  CVSBannerType,
  CVSConfirmationDialogContentComponent
} from 'angular-component-library';
import {Router} from '@angular/router';
import {AppDataService} from '../../../services/app-management/data/app-data.service';
import {AppPermissionsComponent} from '../../../app-management/app-dashboard/app-permissions/app-permissions.component';
import {AddEditModeEnum, StatusCodes} from '../../../enums/add-edit-form.enum';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {HttpErrorResponse} from '@angular/common/http';
import {PBMPermissionInfo} from '../../../models/PBMPermissionInfo';
import {AppManagementService} from '../../../services/app-management/app-management.service';
import {SnackBarWrapperService} from '../../../services/snack-bar-wrapper/snack-bar-wrapper.service';
import {AgGridHelper} from '../../helpers/AgGridHelper';
import {BannerService} from '../../../services/banner-service/banner.service';

@Component({
  selector: 'app-table-action-cell-renderer',
  templateUrl: './table-action-cell-renderer.component.html',
  styleUrls: ['../role-table-action-cell-renderer/role-table-action-cell-renderer.component.scss']
})
export class TableActionCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  parentComponent: AppPermissionsComponent;
  confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent>;
  showDisabledAction: boolean;
  showEnabledAction: boolean;

  constructor(
    private router: Router,
    private appDataService: AppDataService,
    private matDialog: MatDialog,
    private appManagementService: AppManagementService,
    private snackBarWrapperService: SnackBarWrapperService,
    private bannerService: BannerService,
    private cvsBannerService: CVSBannerService
  ) {}

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.parentComponent = this.params.context.this;
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  setTableActionClicked(tableActionClicked: boolean) {
    const data = this.params.node.data;
    data.tableActionClicked = tableActionClicked;
    this.params.api.applyTransaction({update: [data]});
    this.showDisabledAction = this.showDisableMenuItem(this.params.node.data.status);
    this.showEnabledAction = this.showEnableMenuItem(this.params.node.data.status);
  }

  navigateToAddEditPermission() {
    this.router.navigate(['/app-management/manage/' + this.parentComponent.selectedApp.appCode + '/permission'], {
      state: {
        data: this.params.data,
        mode: AddEditModeEnum.EDIT
      }
    });
  }

  openDeleteConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to delete the selected Permission(s)?',
        body:
          '<br>'
          + '<p class="delete-spacing">If these Permissions are not currently linked to any Roles or User Profiles, it</p>'
          + 'will no longer be available for use.'
          + '<br><br><br>'
          + '<p class="red-delete-warning">Warning: This cannot be undone.</p>'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      },
      panelClass: 'delete-confirmation-modal'
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      const nodeToRemove = this.params.node.data;
      this.confirmationDialog.close();

      this.appManagementService.deleteAppPermissionById(nodeToRemove.id).subscribe({
        next: this.deletePermissionSuccessHandler.bind(this, nodeToRemove),
        error: this.deletePermissionErrorHandler.bind(this),
      });
    });

    this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
      this.confirmationDialog.close();
    });
  }

  private deletePermissionSuccessHandler = (nodeToRemove) => {
    this.params.api.applyTransaction({remove: [nodeToRemove]});
    this.appDataService.removePermissionFromCurrentApp(nodeToRemove.id);
    this.bannerService.showSuccessBanner({
      headLine : `${nodeToRemove.name} has been deleted`,
      body : `The permission ${nodeToRemove.name} has been successfully deleted.`,
      removedAfterMilliseconds: 5000
    });
  };

  private deletePermissionErrorHandler = (err: HttpErrorResponse) => {
    const errorBannerData = {
      hideX: false,
      outletId: '#appDashboardBanner',
      headline: 'Permission(s) cannot be deleted',
      closeCallBack: AgGridHelper.setUnableToDeleteStyleAndCloseBanner.bind(this,
        undefined,
        [this.params.node.data],
        this.params.api,
        this.cvsBannerService),
      body: err.error.message,
      bannerType: CVSBannerType.Warning,
    } as CVSBannerComponentData;

    AgGridHelper.setUnableToDeleteStyle(true, [this.params.node.data], this.params.api);

    this.cvsBannerService.sendAlert(errorBannerData);
  };

  openDisableConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to inactivate the selected Permission?',
        body:
          '<br>'
          + 'This Permission will not be available for Roles or User Profiles.'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {

      this.disableAppPermission();
    });

    this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {

      this.confirmationDialog.close();
    });
  }

  openEnableConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to activate the selected Permission?',
        body:
          '<br>'
          + 'This Permission will become available for Roles or User Profiles.'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {

      this.enableAppPermission();
    });

    this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {

      this.confirmationDialog.close();
    });
  }

  disableAppPermission() {
    const pbmPermissionInfo: PBMPermissionInfo = this.params.data;
    pbmPermissionInfo.appId = this.parentComponent.selectedApp.id;
    pbmPermissionInfo.status = StatusCodes.INACTIVE;

    this.appManagementService.updateAppPermission(pbmPermissionInfo).subscribe({
      next: (permission: PBMPermissionInfo) => {
        this.params.node.data.status = StatusCodes.INACTIVE;
        this.params.api.applyTransaction({update: [this.params.node.data]});
        this.appDataService.updatePermissionOnCurrentApp(permission);
        this.bannerService.showSuccessBanner({
          headLine : `${permission.name} has been inactivated`,
          body : `The permission ${permission.name} has been successfully inactivated.`,
          removedAfterMilliseconds: 5000
        });
        this.parentComponent.expandCollapseButtonsComponent.updateExpandCollapseButtons();
      }
    });
  }

  snackBarCenterMessage(successMessage) {
    this.snackBarWrapperService.postCenterBottomSuccessSnackBar(successMessage);
  }

  enableAppPermission() {
    const pbmPermissionInfo: PBMPermissionInfo = this.params.data;
    pbmPermissionInfo.appId = this.parentComponent.selectedApp.id;
    pbmPermissionInfo.status = StatusCodes.ACTIVE;

    this.appManagementService.updateAppPermission(pbmPermissionInfo).subscribe({
      next: (permission: PBMPermissionInfo) => {
        this.params.node.data.status = StatusCodes.ACTIVE;
        this.params.api.applyTransaction({update: [this.params.node.data]});
        this.appDataService.updatePermissionOnCurrentApp(permission);
        this.bannerService.showSuccessBanner({
          headLine : `${permission.name} has been activated`,
          body : `The permission ${permission.name} has been successfully activated.`,
          removedAfterMilliseconds: 5000
        });
        this.parentComponent.expandCollapseButtonsComponent.updateExpandCollapseButtons();
      }
    });
  }

  showDisableMenuItem(status: string) {
    return status === StatusCodes.ACTIVE || status === StatusCodes.PENDING;
  }

  showEnableMenuItem(status: string) {
    return status === StatusCodes.INACTIVE || status === StatusCodes.PENDING;
  }
}
