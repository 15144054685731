import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientSearchType} from '../../enums/client-search-type.enum';
import {ClientHierarchyEnum} from '../../enums/client-hierarchy-enum';
import {
  ColumnApi,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowModelType
} from 'ag-grid-community';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {ClientGroupManagementService} from '../../services/client-group-management/client-group-management.service';
import {HttpErrorResponse} from '@angular/common/http';
import {
  clientGroupName,
  Clients,
  groupCarrierName,
  groupClientCode,
  groupType,
  lineOfBusiness,
  ownerEmail,
  superClientIDGroupName,
  superClientNameGroupName
} from '../../models/ClientGroupColumns';
import {ClientDTO} from '../../models/ClientDTO';
import {ClientSearchComponent} from '../../ag-grid-utils/client-search/client-search.component';
import {AbstractControl, FormBuilder, FormGroup, UntypedFormArray} from '@angular/forms';
import {ClientPaginatorComponent} from '../../ag-grid-utils/client-paginator/client-paginator.component';
import {PBMUserInfo} from '../../models/User';
import {AddUserFormGroup} from '../../enums/add-user-form.enum';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';
import {MatExpansionPanel} from '@angular/material/expansion';
import {ClientProfileModalService} from '../../services/client-profile/modal/client-profile-modal.service';

@Component({
  selector: 'app-user-client-group-access',
  templateUrl: './user-client-group-access.component.html',
  styleUrls: ['./user-client-group-access.component.scss']
})
export class UserClientGroupAccessComponent implements OnInit {
  @Input() addUserForm: FormGroup;
  @Input() userBeingEdited: PBMUserInfo;
  @ViewChild('appClientSearch') clientSearch: ClientSearchComponent;
  @ViewChild('clientsPaginator') clientPaginator: ClientPaginatorComponent;
  @ViewChild('clientGroupExpansionPanel') clientGroupExpansionPanel: MatExpansionPanel;

  groupIdSet: Set<number> = new Set<number>();

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    checkboxSelection: true,
    rowSelection: 'multiple',
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset', 'apply'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  superClientIdColumnDefs = [
    clientGroupName,
    superClientIDGroupName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness
  ];

  superClientGroupNameColumnDefs = [
    clientGroupName,
    superClientNameGroupName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness
  ];

  groupClientCodeColumnDefs = [
    clientGroupName,
    groupClientCode,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness
  ];

  groupCarrierIdColumnDefs = [
    clientGroupName,
    groupCarrierName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness
  ];

  groupCarrierNameColumnDefs = [
    clientGroupName,
    groupCarrierName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness
  ];

  clientGroupNameColumnDefs = [
    clientGroupName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness
  ];

  clientDropdownOptions = [
    {
      label: ClientSearchType.CLIENT_GROUP_NAME,
      colDef: this.clientGroupNameColumnDefs,
      searchParam: 'groupName',

    },
    {
      label: ClientSearchType.SUPER_CLIENT_ID,
      colDef: this.superClientIdColumnDefs,
      searchParam: 'superClientId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SUPER_CLIENT_NAME,
      colDef: this.superClientGroupNameColumnDefs,
      searchParam: 'superClientName',
    },
    {
      label: ClientSearchType.CLIENT_CODE,
      colDef: this.groupClientCodeColumnDefs,
      searchParam: 'clientCode',
      accountLevel: ClientHierarchyEnum.CLIENT_CODE
    },
    {
      label: ClientSearchType.CARRIER_ID,
      colDef: this.groupCarrierIdColumnDefs,
      searchParam: 'carrierId',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.CARRIER_NAME,
      colDef: this.groupCarrierNameColumnDefs,
      searchParam: 'carrierName',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
  ];
  showGrid;
  showErrorResponse;
  context = {this: this};
  isGridReady = false;
  clients: ClientDTO[];
  rowModelType: RowModelType = 'serverSide';
  clientDropdownOption;
  gridApi: GridApi;
  columnApi: ColumnApi;
  isButtonShown = false;
  clientSearchType: ClientSearchType;
  defaultPaginatorSize = 25;

  constructor(private clientGroupManagementService: ClientGroupManagementService,
              private formBuilder: FormBuilder,
              private clientProfileModalService: ClientProfileModalService
  ) {}

  ngOnInit(): void {
    this.getUserClientGroupAccess();
  }

  getUserClientGroupAccess() {
    if (this.userBeingEdited) {
      this.userBeingEdited.assignedClientGroups.forEach(group => {
        const clientGroupFormGroup = {
          group,
          groupSelected: true,
        };
        this.groupIdSet.add(group.id);
        this.userClientGroups.push(this.formBuilder.group(clientGroupFormGroup));
      });
    }
  }

  onGridReady(params) {
    this.isGridReady = true;
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.createServerSideData();

    AgGridHelper.insertRowsPerPageSelector('userClientGroupGrid', 'userClientGroupPaginator');
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
  }

  onDropDownChange() {
    this.clientSearchType = this.clientDropdownOption.label;
  }

  searchClientGroup(searchValue: string) {
    this.clientGroupManagementService.globalSearchValue = searchValue;
    this.showGrid = true;
    this.showErrorResponse = false;

    if (this.isGridReady) {
      this.createServerSideData();
    }
    this.isButtonShown = true;
  }

  createServerSideData() {
    const datasource: IServerSideDatasource = this.createServerSideDatasource();
    this.gridApi.setServerSideDatasource(datasource);
  }

  createServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.gridApi.deselectAll();
        this.gridApi.setColumnDefs(this.clientDropdownOption.colDef);
        this.clientGroupManagementService.searchGroup(
          this.addUserForm.get('userType').value,
          this.clientDropdownOption.searchParam,
          params.request.startRow,
          params.request.endRow,
          params.request.filterModel).subscribe({
          next: this.handleGetClientGroupDataSuccess.bind(this, params),
          error: this.handleGetClientGroupDataFailure.bind(this, params)
        });
      },
    };
  }

  openClientGroupAccessModal(formGroup: AbstractControl) {
    const groupName = formGroup.value?.group?.groupName;
    const groupType = formGroup.value?.group?.groupType;
    const groupDescription = formGroup.value?.group?.groupDescription;
    const clients = formGroup.value?.group?.clients;

    this.clientProfileModalService.openClientGroupAccessModal(
        clients, groupName, groupType, groupDescription);
  }

  private handleGetClientGroupDataSuccess(params: IServerSideGetRowsParams, response: any) {
    params.success({
      rowData: response.data
    });
  }

  private handleGetClientGroupDataFailure(params: IServerSideGetRowsParams, err: HttpErrorResponse) {
    params.fail();
    this.showErrorResponse = true;
    this.showGrid = false;
  }

  onClientSearchValidationError() {
    this.showErrorResponse = false;
  }

  resetGroupSearch() {
    this.isButtonShown = false;
    this.clientDropdownOption = null;
    this.clientSearch.searchValue.reset();
    this.showGrid = false;
    this.showErrorResponse = false;
  }

  public getClientName(params): string {
    let clientName = '';
    const searchParam = this.clientDropdownOption.searchParam;

    switch (searchParam){
      case 'superClientId':
        clientName = this.clientIdentifierContainsSearchValue(params, 'superClientId')[0].superClientName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'superClientId')[0].superClientId + ')';
        break;
      case 'superClientName':
        clientName = this.clientIdentifierContainsSearchValue(params, 'superClientName')[0].superClientName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'superClientName')[0].superClientId  + ')';
        break;
      case 'carrierId':
        clientName =  this.clientIdentifierContainsSearchValue(params, 'carrierId')[0].carrierName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'carrierId')[0].carrierId  + ')';
      break;
      case 'carrierName':
        clientName =  this.clientIdentifierContainsSearchValue(params, 'carrierName')[0].carrierName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'carrierName')[0].carrierId  + ')';
        break;
      default: break;
    }
    return clientName;
  }

  private clientIdentifierContainsSearchValue(params, fieldToFilter) {
     return params.data.clients.filter(client => client[fieldToFilter].toUpperCase()
      .includes(this.clientGroupManagementService.globalSearchValue.toUpperCase()));
  }

  public openClientProfileModal(params) {
    const client = this.getClient(params);
    const isFromCarrier = client.accountLevel === ClientHierarchyEnum.CARRIER ? true : null;
    this.clientProfileModalService.openClientProfileModal(client, isFromCarrier);
  }

  private getClient(params): ClientDTO {
    const searchParam = this.clientDropdownOption.searchParam;
    return this.clientIdentifierContainsSearchValue(params, searchParam)[0];
  }

  groupCheckboxChange(event: MatCheckboxChange) {
    this.addUserForm.markAsDirty();
    if (event.checked) {
      this.userClientGroups.push(this.formBuilder.group(event.source.value));
    } else {
      this.userClientGroups.removeAt(this.getGroupIndex(event.source.value));
    }
  }

  getGroupIndex(eventValue) {
    const group = eventValue.value.group;
    const groupsFromForm = this.userClientGroups.value.map(formControl => formControl.group);
    return groupsFromForm.findIndex(currentGroup => currentGroup.id === group.id);
  }

  onRowSelected(event) {
    this.addUserForm.markAsDirty();
    if (event.node.selected) {
      if (!this.groupIdSet.has(event.data.id)) {
        this.userClientGroups.push(this.formBuilder.group({
          group: event.data,
          groupSelected: true,
        }));
        this.groupIdSet.add(event.data.id);
      }
      this.clientGroupExpansionPanel.expanded = true;
    }
  }

  get userClientGroups() {
    return this.addUserForm.get(AddUserFormGroup.GROUPS) as UntypedFormArray;
  }
}

