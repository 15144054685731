<div class="roles-container">
  <h2 class="header-padding roles-header">Roles</h2>

  <div class="header-container">
    <app-search #searchRoleComponent [gridApi]="gridApi"></app-search>
    <div class="roles-button-container">
      <app-expand-collapse-buttons #expandCollapseButtonsComponent [gridApi]="gridApi"></app-expand-collapse-buttons>
      <button class="pds-btn-primary" style="width: 146px;" (click)="navigateToAddEditRole()">
        <mat-icon svgIcon="plus--s"></mat-icon>
        Add Role
      </button>
    </div>
  </div>

  <ag-grid-angular *ngIf="selectedApp"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    [components]="frameworkComponents"
    [rowHeight]="32"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [context]="context"
    [pagination]=true
    [treeData]="true"
    [getDataPath]="AgGridHelper.getRoleDataPath"
    [doesExternalFilterPass]="doesExternalFilterPass"
    [isExternalFilterPresent]="isExternalFilterPresent"
    (rowGroupOpened)="updateAllRowsExpandedState()"
    [rowClassRules]="rowClassRules"
    class="ag-theme-alpine roles-grid">
  </ag-grid-angular >
  <app-paginator-rows-per-page
    [defaultPaginatorSize]="defaultPaginatorSize"
    [pageSizeOptions]="[10, 15, 20]"
    [gridApi]="gridApi"
    [columnApi]="columnApi">
  </app-paginator-rows-per-page>

</div>
