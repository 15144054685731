import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {PBMAppInfo} from '../../../models/AppInfo';
import {AddEditModeEnum, StatusCodes} from '../../../enums/add-edit-form.enum';
import {Router} from '@angular/router';
import {ColumnApi, GridApi, GridReadyEvent, RowClassRules, RowNode} from 'ag-grid-community';
import {AgGridHelper} from '../../../ag-grid-utils/helpers/AgGridHelper';
import {AppManagementService} from '../../../services/app-management/app-management.service';
import {
  RoleCellRendererComponent
} from '../../../ag-grid-utils/cell-renderers/role-cell-renderer/role-cell-renderer.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SearchComponent} from '../../../ag-grid-utils/search/search.component';
import {
  RoleTableActionCellRendererComponent
} from '../../../ag-grid-utils/cell-renderers/role-table-action-cell-renderer/role-table-action-cell-renderer.component';
import {AppDataService} from '../../../services/app-management/data/app-data.service';
import {
  ExpandCollapseButtonsComponent
} from '../../../ag-grid-utils/expand-collapse-buttons/expand-collapse-buttons.component';
import {Subscription} from 'rxjs';
import {ComponentTypeEnum} from '../../../enums/component-types.enum';

@Component({
  selector: 'app-roles',
  templateUrl: './app-roles.component.html',
  styleUrls: ['./app-roles.component.scss']
})
export class AppRolesComponent implements OnDestroy{
  protected readonly AgGridHelper = AgGridHelper;

  @Input() selectedApp: PBMAppInfo;
  context = {this: this};
  gridApi: GridApi;
  confirmationDialog: MatDialogRef<any>;
  defaultPaginatorSize = 10;
  columnApi: ColumnApi;
  appDataSubscription: Subscription = new Subscription();
  componentType: ComponentTypeEnum = ComponentTypeEnum.APP_ROLES;

  frameworkComponents = {
    roleCellRendererComponent: RoleCellRendererComponent,
    roleTableActionCellRenderer: RoleTableActionCellRendererComponent
  };

  rowClassRules: RowClassRules = {
    'row-table-action-clicked': (params) => {
      if (params.data) {
        return params.data.tableActionClicked;
      } else {
        return false;
      }
    },
    'role-unable-to-delete': (params) => {
      if (params.data) {
        return params.data.unableToDelete;
      } else {
        return false;
      }
    },
  };

  @ViewChild('searchRoleComponent') searchComponent: SearchComponent;
  @ViewChild('expandCollapseButtonsComponent') expandCollapseButtonsComponent: ExpandCollapseButtonsComponent;

  fieldsIncludedInSearch: string[] = ['capabilityName', 'roleName', 'name', 'permissionDescription',
    'permissionCode', 'status', 'permissionType'];

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: false,
    suppressMenu: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  columnDefs = [
    {
      headerName: 'App Capability',
      field: 'capabilityName',
    },
    {
      headerName: 'Permission Name',
      field: 'name',
    },
    {
      headerName: 'Permission ID',
      field: 'permissionCode',
    },
    {
      headerName: 'Permission Description',
      field: 'permissionDescription',
      width: 150,
      flex: 2,
      cellRenderer: (params) => {
        if(!this.AgGridHelper.isRole(params)) {
          return params.value;
        }
      }
    },
    {
      headerName: 'Permission Type',
      field: 'permissionType',
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        values: [StatusCodes.ACTIVE, StatusCodes.INACTIVE],
      },
      suppressMenu: false,
      filterValueGetter: (params) => {
        return this.AgGridHelper.isRole(params) ? params.data.status : '';
      },
      cellRenderer: (params) => {
        if(this.AgGridHelper.isRole(params)) {
          return params.value;
        }
      }
    },
    {
      headerName: 'Actions',
      cellRenderer: 'roleTableActionCellRenderer',
    }
  ];

  autoGroupColumnDef = {
    headerName: 'Role Name',
    minWidth: 220,
    sortable: true,
    suppressMenu: false,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: true,
    },
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'roleCellRendererComponent'
    },
    valueGetter: (params) => {
      return this.AgGridHelper.isRole(params) ? params.data.roleName : null;
    }
  };

  constructor(
    private appManagementService: AppManagementService,
    private router: Router,
    private matDialog: MatDialog,
    private appDataService: AppDataService
  ) {}

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    AgGridHelper.insertRowsPerPageSelector();
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
    this.appDataSubscription = this.appDataService.currentApp$.subscribe(app => {
      const roles = app.roles;
      const rolePermissionList = this.AgGridHelper.convertRolesToTreeStructure(roles);
      this.gridApi.setRowData(rolePermissionList);
    });
  }

  doesExternalFilterPass(node: RowNode) {
    return AgGridHelper.doesExternalFilterPass(node,
      this.context.this.searchComponent.searchValue,
      this.context.this.fieldsIncludedInSearch);
  }

  isExternalFilterPresent() {
    return !!this.context.this.searchComponent?.searchValue;
  }

  updateAllRowsExpandedState() {
    this.expandCollapseButtonsComponent.updateExpandCollapseButtons();
  }

  navigateToAddEditRole() {
    this.router.navigate(['/app-management/manage/' + this.selectedApp.appCode + '/role'], {state: {mode: AddEditModeEnum.ADD}});
  }

  ngOnDestroy(){
    this.appDataSubscription.unsubscribe();
  }
}
