import {AfterViewInit, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ClientManagementService} from '../../services/client-management/client-management.service';
import {ColumnApi, GridApi} from 'ag-grid-community';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {
  accountId,
  accountIdWithCheckbox,
  accountLevel,
  accountName,
  accountNameWithCheckbox,
  carrierIdWithCheckbox,
  carrierIdWithLink,
  carrierName,
  carrierNameWithCheckbox,
  carrierRdcLevel,
  clientCode,
  clientCodeWithCheckbox,
  clientProfileForAddClient,
  fromDate,
  marketSegment,
  sfdcAccountIdWithLink,
  status,
  superClientId,
  superClientIdWithCheckbox,
  superClientIdWithLink,
  superClientName,
  superClientNameWithCheckbox,
  superClientRdcAccountWithFilter,
  superClientRdcLevel,
  thruDate
} from '../../models/ClientHierarchyColumns';
import {Router} from '@angular/router';
import {ClientSearchComponent} from '../../ag-grid-utils/client-search/client-search.component';
import {ClientSearchType} from '../../enums/client-search-type.enum';
import {BannerService} from '../../services/banner-service/banner.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {ClientProfileModalComponent} from '../../common/client-profile-modal/client-profile-modal.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ClientDataService} from '../../services/client-profile/data/client-data.service';
import {SuperClient} from '../../models/ClientHierarchy';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements AfterViewInit {
  title = 'Client Profile';
  label = 'Search for a client profile to add to myPBM.';
  initiatedSearch = false;

  searchValue = '';
  @ViewChild('appClientSearch') clientSearchComponent: ClientSearchComponent;

  context = {this: this};
  gridApi: GridApi;
  columnApi: ColumnApi;

  isGridReady = false;
  showGrid = false;
  defaultPaginatorSize = 50;

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  superClientIdColumnDefs = [
    superClientIdWithCheckbox,
    superClientName,
    clientProfileForAddClient,
    accountId,
    accountName,
    accountLevel,
    status,
    superClientRdcAccountWithFilter,
    superClientRdcLevel,
    marketSegment
  ];

  superClientNameColumnDefs = [
    superClientNameWithCheckbox,
    superClientIdWithLink,
    clientProfileForAddClient,
    accountId,
    accountName,
    accountLevel,
    status,
    superClientRdcAccountWithFilter,
    superClientRdcLevel,
    marketSegment
  ];

  accountIdColumnDefs = [
    accountIdWithCheckbox,
    accountName,
    clientProfileForAddClient,
    superClientId,
    superClientName,
    status,
    accountLevel,
    superClientRdcAccountWithFilter,
    superClientRdcLevel,
    marketSegment
  ];

  accountNameColumnDefs = [
    accountNameWithCheckbox,
    sfdcAccountIdWithLink,
    clientProfileForAddClient,
    accountLevel,
    status,
    superClientId,
    superClientName,
    superClientRdcAccountWithFilter,
    superClientRdcLevel,
    marketSegment,
  ];

  clientCodeColumnDefs = [
    clientCodeWithCheckbox,
    clientProfileForAddClient
  ];

  carrierIdColumnDefs = [
    carrierIdWithCheckbox,
    carrierName,
    clientProfileForAddClient,
    fromDate,
    thruDate,
    clientCode,
    status,
    superClientRdcAccountWithFilter,
    carrierRdcLevel,
  ];

  carrierNameColumnDefs = [
    carrierNameWithCheckbox,
    carrierIdWithLink,
    clientProfileForAddClient,
    fromDate,
    thruDate
  ];

  addClientOptions = [
    {
      label: ClientSearchType.SUPER_CLIENT_ID,
      methodToCall: 'getClientHierarchyBySuperClientId',
      colDef: this.superClientIdColumnDefs,
      sort: null,
      fieldToShowOnBanner: 'superClientId'
    },
    {
      label: ClientSearchType.SUPER_CLIENT_NAME,
      methodToCall: 'getClientHierarchyBySuperClientName',
      colDef: this.superClientNameColumnDefs,
      sort: 'superClientName',
      fieldToShowOnBanner: 'superClientName'
    },
    {
      label: ClientSearchType.CLIENT_CODE,
      methodToCall: 'getClientHierarchyByClientCode',
      colDef: this.clientCodeColumnDefs,
      sort: null,
      fieldToShowOnBanner: 'clientCode'
    },
    {
      label: ClientSearchType.CARRIER_ID,
      methodToCall: 'getClientHierarchyByCarrierId',
      colDef: this.carrierIdColumnDefs,
      sort: null,
      fieldToShowOnBanner: 'carrierId'
    },
    {
      label: ClientSearchType.CARRIER_NAME,
      methodToCall: 'getClientHierarchyByCarrierName',
      colDef: this.carrierNameColumnDefs,
      sort: null,
      fieldToShowOnBanner: 'carrierName'
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_ID,
      methodToCall: 'getClientHierarchyByAccountId',
      colDef: this.accountIdColumnDefs,
      sort: null,
      fieldToShowOnBanner: 'accountId'
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_NAME,
      methodToCall: 'getClientHierarchyByAccountName',
      colDef: this.accountNameColumnDefs,
      sort: 'accountName',
      fieldToShowOnBanner: 'accountName'
    }
  ];
  addClientOption = this.addClientOptions[0];
  clientSearchType: ClientSearchType = this.addClientOption.label;
  lastSearchedClientSearchType: ClientSearchType = this.addClientOption.label;
  showSpinner: Subject<boolean> = new BehaviorSubject(false);

  constructor(private clientManagementService: ClientManagementService,
              private bannerService: BannerService,
              private router: Router,
              private cdRef: ChangeDetectorRef,
              private confirmationDialog: MatDialog,
              private clientDataService: ClientDataService) {

    if (this.router.getCurrentNavigation().extras.state === undefined) {
      this.router.navigate(['/client-management']).then();
    }

    if (this.router.getCurrentNavigation().extras.state.searchValue?.length > 0) {
      this.addClientOption = this.addClientOptions.find(option => option.label === this.router
        .getCurrentNavigation().extras.state.selectedOption);
      this.onDropDownChange();
      this.searchValue = this.router.getCurrentNavigation().extras.state.searchValue;
    }
  }

  ngAfterViewInit(): void {
    if (this.searchValue.length > 0) {
      this.clientSearchComponent.searchValue.setValue(this.searchValue);
      this.clientSearchComponent.initiateClientSearch(null);
    }
    this.cdRef.detectChanges();
  }

  onGridReady(params) {
    this.isGridReady = true;

    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.setColumnDefs(this.addClientOption.colDef);

    AgGridHelper.insertRowsPerPageSelector();
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
  }

  getClientHierarchy(searchValue: string) {
    this.showGrid = true;

    if (this.isGridReady) {
      this.gridApi.showLoadingOverlay();
      this.gridApi.setColumnDefs(this.addClientOption.colDef);
    }

    this.clientManagementService[this.addClientOption.methodToCall](searchValue).subscribe({
        next: this.handleGetClientHierarchyDataSuccess.bind(this),
        error: this.handleGetClientHierarchyDataFailure.bind(this)
      }
    );
  }

  handleGetClientHierarchyDataSuccess(res: any) {
    if (!res) {
      this.router.navigate(['/client-management'], {
        state: {
          clientNotFound: true,
          selectedOption: this.addClientOption.label
        }
      });
    }

    if (this.isGridReady) {
      this.lastSearchedClientSearchType = this.addClientOption.label;
      this.label = res ? 'Based on the search criteria, the following client(s) exist within CVS/Caremark. ' +
        'Select the client(s) to add to myPBM' : this.label;
      if (this.addClientOption.sort) {
        this.gridApi.setRowData(res?.sort(
          (superClient1, superClient2) => superClient1[this.addClientOption.sort] > superClient2[this.addClientOption.sort] ? 1 : -1)
        );
      } else {
        this.gridApi.setRowData(res);
      }
    }
  }

  handleGetClientHierarchyDataFailure() {
    this.gridApi.setRowData(null);
  }

  onDropDownChange() {
    this.clientSearchType = this.addClientOption.label;
  }

  saveClients() {
    this.showSpinner.next(true);
    if (this.gridApi.getSelectedRows().length > 0 && [ClientSearchType.SUPER_CLIENT_ID,
      ClientSearchType.SALESFORCE_ACCOUNT_ID].includes(this.addClientOption.label)) {
      this.clientManagementService.addSuperClient(this.gridApi.getSelectedRows()[0]).subscribe(
        {
          next: this.handleAddSuperClientSuccess.bind(this),
          error: this.handleAddSuperClientFailure.bind(this)
        }
      );
    } else if (this.gridApi.getSelectedRows().length > 0 && [
      ClientSearchType.SUPER_CLIENT_NAME,
      ClientSearchType.SALESFORCE_ACCOUNT_NAME].includes(this.addClientOption.label)) {
      this.clientManagementService.addFullSuperClientHierarchy(this.gridApi.getSelectedRows()[0]).subscribe(
        {
          next: this.handleAddSuperClientSuccess.bind(this),
          error: this.handleAddSuperClientFailure.bind(this)
        }
      );
    } else if (this.gridApi.getSelectedRows().length > 0 && [
      ClientSearchType.CLIENT_CODE, ClientSearchType.CARRIER_ID].includes(this.addClientOption.label)) {
      this.clientManagementService.addFullSuperClientHierarchy(this.clientManagementService.clientHierarchyServiceResult).subscribe(
        {
          next: this.handleAddSuperClientSuccess.bind(this),
          error: this.handleAddSuperClientFailure.bind(this)
        }
      );
    } else if (this.gridApi.getSelectedRows().length > 0 && [
       ClientSearchType.CARRIER_NAME].includes(this.addClientOption.label)) {
      this.clientManagementService.addFullSuperClientHierarchyByCarrierId(this.gridApi.getSelectedRows()[0]).subscribe(
        {
          next: this.handleAddSuperClientSuccess.bind(this),
          error: this.handleAddSuperClientFailure.bind(this)
        }
      );
    }
  }

  handleAddSuperClientSuccess() {
    this.showSpinner.next(false);
    this.clientManagementService.clientManagementNotification.next({
      headLine: `${this.addClientOption.label} Successfully Added`,
      body: `${this.addClientOption.label} has been successfully added to IAM`,
      bannerLinks: [{
        linkText: `${this.addClientOption.label}: ${this.gridApi.getSelectedRows()[0][this.addClientOption.fieldToShowOnBanner]}`,
        linkFunc: () => {
        }
      }]
    });
    this.router.navigate(['/client-management']);
  }

  handleAddSuperClientFailure(err) {
    this.showSpinner.next(false);
    let errorBannerData;
    if(err.error.status === 409) {
      errorBannerData = {
        headLine: `${this.addClientOption.label} Already Exists`,
        body: `The ${this.addClientOption.label} you entered already exists in IAM. Please Try Again.`,
        bannerLinks: [{
          linkText: `${this.addClientOption.label}: ${this.gridApi.getSelectedRows()[0][this.addClientOption.fieldToShowOnBanner]}`,
          linkFunc: () => {}
        }]
      };
    } else {
      errorBannerData = {
        headLine: 'Error Saving Client Data',
        body: err.error.message,
      };
    }
    this.bannerService.showFailureBanner(errorBannerData);
  }

  onBack() {
    this.router.navigate(['/client-management']);
  }

  setSearchFromLink(rowData, clientSearchType: ClientSearchType, searchParam: string) {
    if (rowData[searchParam]) {
      this.addClientOption = this.addClientOptions.find(option => option.label === clientSearchType);
      this.searchValue = rowData[searchParam];
      this.initiatedSearch = true;
      this.onDropDownChange();
    }
  }

  startSearchFromLink() {
    if (this.initiatedSearch) {
      this.clientSearchComponent.searchValue.setValue(this.searchValue);
      this.clientSearchComponent.initiateClientSearch(null);
    }
    this.initiatedSearch = false;
  }

  openClientProfileModal(client: any, clientCode?: any, fromCarrier?: any) {
    this.confirmationDialog.open(ClientProfileModalComponent, {
      data: {
        clientCode,
        fromCarrier,
      },
      panelClass: 'client-profile-modal',
      disableClose: true
    });
  }

  setCurrentClient(client: SuperClient) {
    this.clientDataService.setCurrentClient(client);
  }

  getCurrentClient() {
    return this.clientDataService.currentClient$.value;
  }

  getFullHierarchyForAddClientAccess(client: any) {
    switch (this.lastSearchedClientSearchType) {
      case ClientSearchType.SUPER_CLIENT_ID:
      case ClientSearchType.SALESFORCE_ACCOUNT_ID:
        this.setCurrentClient(client);
        this.openClientProfileModal(this.getCurrentClient());
        break;
      case ClientSearchType.SUPER_CLIENT_NAME:
      case ClientSearchType.SALESFORCE_ACCOUNT_NAME:
        this.showSpinner.next(true);
        this.clientManagementService.getClientHierarchyByAccountId(client.accountId).subscribe(res => {
          this.showClientProfileModal(res);
        });
        break;
      case ClientSearchType.CLIENT_CODE:
        this.showSpinner.next(true);
        this.clientManagementService.getClientHierarchyByAccountId(this.clientManagementService.clientHierarchyServiceResult.accountId)
          .subscribe(res => {
            this.showClientProfileModal(res);
          });
        break;
      case ClientSearchType.CARRIER_ID:
        this.showSpinner.next(true);
        this.clientManagementService.getClientHierarchyByAccountId(this.clientManagementService.clientHierarchyServiceResult.accountId)
          .subscribe(res => {
            const clientCode = this.clientManagementService.clientHierarchyServiceResult.clients[0].clientCode;
            this.showClientProfileModal(res, clientCode, true);
          });
        break;
      case ClientSearchType.CARRIER_NAME:
        this.showSpinner.next(true);
        this.clientManagementService.getFullClientHierarchyByCarrierId(client.carrierId).subscribe({
          next: (res) => {
            this.showSpinner.next(false);
            this.setCurrentClient(res[0]);
            const clientCode = this.getCurrentClientCode(res, client);
            this.openClientProfileModal(this.getCurrentClient(), clientCode, true);
          },
          error: (err) => {
            this.showSpinner.next(false);
          }
        });
        break;
      default:
        break;
    }
  }

  private getCurrentClientCode(superClient: SuperClient[], currentCarrier: any) {
    let currentClientCode = '';
    superClient[0].clients.forEach(client => {
      client.carriers.forEach(carrier => {
        if (carrier.carrierId === currentCarrier.carrierId) {
          currentClientCode = client.clientCode;
        }
      });
    });
    return currentClientCode;
  }

  private showClientProfileModal(superClient: SuperClient[], clientCode?: any, fromCarrier?: any) {
    this.showSpinner.next(false);
    this.setCurrentClient(superClient[0]);
    this.openClientProfileModal(this.getCurrentClient(), clientCode, fromCarrier);
  }
}
