import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClientSearchType} from '../../enums/client-search-type.enum';
import {ClientPaginatorComponent} from '../client-paginator/client-paginator.component';
import {ClientDTO} from '../../models/ClientDTO';
import {ClientSearchComponent} from '../client-search/client-search.component';
import {ClientManagementService} from '../../services/client-management/client-management.service';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatLegacyRadioChange as MatRadioChange} from '@angular/material/legacy-radio';
import {FormControl} from '@angular/forms';
import {ClientProfileModalService} from '../../services/client-profile/modal/client-profile-modal.service';
import {ClientHierarchyEnum} from '../../enums/client-hierarchy-enum';

@Component({
  selector: 'app-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit {
  @ViewChild('clientsPaginator') clientPaginator: ClientPaginatorComponent;
  @ViewChild('clientSearch') clientSearchComponent: ClientSearchComponent;

  @Input() isAllClientOptionShown = false;
  @Input() clientReportOption: FormControl<any>;

  @Output() clientSelected: EventEmitter<ClientDTO> = new EventEmitter<ClientDTO>();
  @Output() clientDropDownChanged: EventEmitter<any> = new EventEmitter();

  protected readonly ClientSearchType = ClientSearchType;

  isClientSearchShown = false;
  clients: ClientDTO[];
  totalClients: number;
  isClientNotFoundErrorMessageShown = false;
  isButtonShown = false;

  pageSize = 10;
  start = 0;
  end = 10;

  clientReportOptions = [
    {
      label: ClientSearchType.SUPER_CLIENT_ID,
      searchParam: 'superClientId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SUPER_CLIENT_NAME,
      searchParam: 'superClientName',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.CLIENT_CODE,
      searchParam: 'clientCode',
      accountLevel: ClientHierarchyEnum.CLIENT_CODE
    },
    {
      label: ClientSearchType.CARRIER_ID,
      searchParam: 'carrierId',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.CARRIER_NAME,
      searchParam: 'carrierName',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_ID,
      searchParam: 'accountId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_NAME,
      searchParam: 'accountName',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    }
  ];

  clientSearchType: ClientSearchType;
  lastSearchedClientSearchType: ClientSearchType;

  constructor(
    private clientManagementService: ClientManagementService,
    private clientProfileModalService: ClientProfileModalService,
  ) {
  }

  ngOnInit(): void {
    this.clientReportOption = this.clientReportOption ? this.clientReportOption : new FormControl<ClientSearchType>(null);

    if (this.isAllClientOptionShown) {
      this.clientReportOptions.unshift({
        label: ClientSearchType.ALL_CLIENTS,
        searchParam: '',
        accountLevel: undefined
      });
    }
  }

  onClientDropDownChange() {
    this.clientSearchType = this.clientReportOption.value['label'];
    this.isClientSearchShown = this.clientSearchType !== ClientSearchType.ALL_CLIENTS;
    if (this.clientSearchType === ClientSearchType.ALL_CLIENTS) {
      this.clients = null;
      this.clientSelected.emit(null);
      this.isButtonShown = false;
    }
    this.clientDropDownChanged.emit();
  }

  onSearch(searchValue) {
    this.resetPaginator();
    this.getClientData(searchValue);
  }

  onClientSearchValidationError() {
    this.isClientNotFoundErrorMessageShown = false;
    this.clients = null;
  }

  onClientSelect(event: MatRadioChange) {
    this.clientSelected.emit(event.value);
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.start = pageEvent.pageIndex * pageEvent.pageSize;
    this.end = this.start + pageEvent.pageSize;
    this.getClientData(this.clientManagementService.globalSearchValue);
  }

  reset() {
    this.clientReportOption.reset();
    this.isClientSearchShown = false;
    this.isClientNotFoundErrorMessageShown = false;
    this.clients = null;
    this.isButtonShown = false;
    this.resetPaginator();
  }

  private getClientData(searchValue) {
    this.clientManagementService.globalSearchValue = searchValue;
    this.lastSearchedClientSearchType = this.clientReportOption.value['label'];
    this.isClientNotFoundErrorMessageShown = false;
    this.resetClientData();

    this.clientManagementService.searchClients(
                this.clientReportOption.value['accountLevel'],
                this.clientReportOption.value['searchParam'],
                this.start,
                this.end).subscribe({
        next: this.handleSearchClientSuccess.bind(this),
        error: this.handleSearchClientError.bind(this)
      });
  }

  private handleSearchClientSuccess(client) {
    this.clients = client.data;
    this.totalClients = client.count;
    this.isButtonShown = true;
  }

  private handleSearchClientError(error) {
    if (error.status === 404) {
      this.isClientNotFoundErrorMessageShown = true;
      this.clients = null;
      this.isButtonShown = false;
    }
  }

  private resetClientData() {
    this.clients = [];
    this.totalClients = 0;
  }

  private resetPaginator() {
    this.start = 0;
    this.end = 10;
    this.pageSize = 10;
    if (this.clientPaginator) { this.clientPaginator.paginator.pageIndex = 0; }
  }

  openClientProfileModal(client: any, isFromCarrier?: any) {
    this.clientProfileModalService.openClientProfileModal(client, isFromCarrier);
  }

}
