import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-client-paginator',
  templateUrl: './client-paginator.component.html',
  styleUrls: ['./client-paginator.component.scss']
})
export class ClientPaginatorComponent implements AfterViewInit, OnChanges {

  @Input() totalRowCount: number;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @ViewChild('clientsPaginator', {static: true}) paginator: MatPaginator;

  pageDescriptionContainer!: HTMLElement;
  pageDescription: string;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.setItemsPerPageAndRangeLabel();
    this.createPageDescriptionElement();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalRowCount && !changes.totalRowCount.firstChange) {
      this.setCurrentPageDescription();
    }
  }

  handlePageEvent($event: PageEvent) {
    this.setCurrentPageDescription();
    this.page.emit($event);
  }

  private setItemsPerPageAndRangeLabel() {
    this.paginator._intl.itemsPerPageLabel = 'Rows per page';

    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      if (length === 0) {
        return '0 to 0 of 0';
      }

      const start = (page * pageSize);
      const end = (start + pageSize) < length ? (start + pageSize) : length;
      return `${start + 1} to ${end} of ${length}`;
    };
  }

  private createPageDescriptionElement() {
    const actionContainer = this.elementRef.nativeElement.querySelector('div.mat-paginator-range-actions');
    const nextButton = this.elementRef.nativeElement.querySelector('button.mat-paginator-navigation-next');

    this.pageDescriptionContainer = this.renderer.createElement('div') as HTMLElement;
    this.renderer.addClass(this.pageDescriptionContainer, 'page-num-div');

    this.renderer.insertBefore(actionContainer, this.pageDescriptionContainer, nextButton);
  }

  private setCurrentPageDescription() {
    if (this.pageDescription) {
      this.renderer.removeChild(this.pageDescriptionContainer, this.pageDescription);
    }
    const totalPage = Math.ceil(this.totalRowCount / this.paginator.pageSize);
    const currentPage = totalPage === 0 ? 0 : this.paginator.pageIndex + 1;
    this.pageDescription = this.renderer.createText(`Page ${currentPage} of ${totalPage}`);
    this.renderer.appendChild(this.pageDescriptionContainer, this.pageDescription);
  }
}
