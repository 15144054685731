<mat-radio-group (change)="generalAccessChanged(params)" [(ngModel)]="generalAccessPermissionType">
  <mat-radio-button value="VIEW" class="radio-button-margin">View</mat-radio-button>
  <mat-radio-button value="VIEW_EDIT">View & Edit</mat-radio-button>

  <mat-error class="general-access-permission-type-error" *ngIf="isFormControlInvalid()">
    <mat-icon class="general-access-permission-type-error-icon" svgIcon="warning-f--xs"></mat-icon>
    <span class="general-access-permission-type-error-message">Permission type is required</span>
  </mat-error>

</mat-radio-group>
