<button class="pds-icon-only-btn"
        aria-label="Open action menu"
        [matMenuTriggerFor]="menu">
  <mat-icon id="three-vertical-dot-icon" svgIcon="more-vert-f--s" role="img"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="action-cell-render-menu" xPosition="after" yPosition="below">
  <div>
    <button class="pds-btn-text-only action-menu-btn" mat-menu-item (click)="navigateToAddEditUserManagement()">
      <mat-icon svgIcon="pencil--s"></mat-icon>
      Edit
    </button>
  </div>
  <div>
    <button class="pds-btn-text-only action-menu-btn" mat-menu-item (click)="navigateToCopy()" *ngIf="params.data.basicUserInfo?.active && isAllowedToCopyMyPBMAdmin">
      <mat-icon svgIcon="duplicate--s"></mat-icon>
      Copy
    </button>
  </div>
</mat-menu>
