import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {PBMAppInfo} from '../../../models/AppInfo';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppOverviewFormFields, UserAccessType} from '../../../enums/add-user-form.enum';
import {CVSConfirmationDialogContentComponent, Direction} from 'angular-component-library';
import {CurrentUserService} from '../../../services/current-user/current-user.service';
import {PBMUserInfo} from '../../../models/User';
import {AuthRoles} from '../../../enums/auth-roles.enum';
import {UserLocationEnum} from '../../../enums/user-location.enum';
import {PBMAccessTypeInfo} from '../../../models/PBMAccessTypeInfo';
import {AppManagementService} from '../../../services/app-management/app-management.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BehaviorSubject, Subject} from 'rxjs';
import {SnackBarWrapperService} from '../../../services/snack-bar-wrapper/snack-bar-wrapper.service';
import {AppDataService} from '../../../services/app-management/data/app-data.service';
import {AppDashboardComponent} from '../app-dashboard.component';

@Component({
  selector: 'app-overview',
  templateUrl: './app-overview.component.html',
  styleUrls: ['./app-overview.component.scss']
})
export class AppOverviewComponent implements AfterViewInit, OnInit, OnChanges {
  selectedApp: PBMAppInfo;
  @Input() appCode: string;
  @Input() tabChanged: boolean;
  @Output() appOverviewChange = new EventEmitter<boolean>();
  previouslySelectedTabIndex: number;

  showSpinner: Subject<boolean> = new BehaviorSubject(false);

  currentUser: PBMUserInfo;

  addAppForm: UntypedFormGroup = this.formBuilder.group({
    clientProvisioningRequired: [false, Validators.required],
    exposesMemberData: [false, Validators.required],
    usesMasking: [false, Validators.required],
    isActive: [false, Validators.required],
    appAccessTypes: this.formBuilder?.array([]),
    appLocations: this.formBuilder?.array([])
  });

  controlNameToLabelMap = {
    cvsHealthColleague: UserAccessType.CVS_HEALTH_COLLEAGUE,
    clientUser: UserAccessType.CLIENT_USER,
    consultantUser: UserAccessType.CONSULTANT_USER,
    onshore: 'Onshore Users',
    offshore: 'Offshore Users'
  };

  right = Direction.RIGHT;
  confirmationDialog: MatDialogRef<any>;
  tabChangedConfirmationDialog: MatDialogRef<any>;

  constructor(private formBuilder: UntypedFormBuilder,
              private cdRef: ChangeDetectorRef,
              private currentUserService: CurrentUserService,
              private matDialog: MatDialog,
              private appManagementService: AppManagementService,
              private snackBarWrapperService: SnackBarWrapperService,
              private appDataService: AppDataService,
              private parentComponent: AppDashboardComponent
  ) {}

  ngOnInit() {
    this.currentUserService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngAfterViewInit(): void {
    this.appManagementService.getAppByAppCode(this.appCode).subscribe(app => {
      this.selectedApp = app;
      this.initForm();
      this.onFormValueChange();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabChanged && !changes.tabChanged.firstChange) {
      this.previouslySelectedTabIndex = this.parentComponent.selectedTabIndex;
      setTimeout(() => {
        this.parentComponent.selectedTabIndex = 2;
      }, 0);
      this.openTabChangedConfirmationModal();
    }
  }

  initForm() {
    this.createUserAccessTypeCheckboxes();
    this.createLocationCheckboxes();

    this.clientProvisioningRequired.setValue(this.selectedApp.clientProvisioningRequired);
    this.exposesMemberData.setValue(this.selectedApp.exposesMemberData);
    this.usesMasking.setValue(this.selectedApp.usesMasking);
    this.isActive.setValue(this.selectedApp.active);

    const adminRole = AuthRoles.MYPBM_ADMIN;
    const appAdminRole = AuthRoles.APP_ADMIN;
    const usersRoles: string[] = Object.values(this.currentUser.appRoles).filter((role: string) =>
      role.includes(adminRole) || role.includes(appAdminRole)
    );
    const isAdmin = usersRoles.some(role => role.includes(AuthRoles.MYPBM_ADMIN) || role.includes(AuthRoles.APP_ADMIN));

    if (!isAdmin) {
      this.disableFormFields();
    }

    this.cdRef.detectChanges();
  }

  private createUserAccessTypeCheckboxes() {
    const appAccessTypeFormFields = [
      AppOverviewFormFields.CVS_HEALTH_COLLEAGUE,
      AppOverviewFormFields.CLIENT_USER,
      AppOverviewFormFields.CONSULTANT_USER
    ];

    appAccessTypeFormFields.forEach(accessType => {
      this.appAccessTypes.controls.push(this.formBuilder.group({[accessType]: false, accessType}));
    });

    appAccessTypeFormFields.forEach((field, index) => {
      this.selectedApp.appAccessTypes.forEach(appAccessType => {
        if (appAccessType.accessType === this.controlNameToLabelMap[field]) {
          this.appAccessTypes.controls[index].get(field).setValue(true);
        }
      });
    });
  }

  private createLocationCheckboxes() {
    const appLocationFormFields = [
      AppOverviewFormFields.OFFSHORE,
      AppOverviewFormFields.ONSHORE
    ];

    appLocationFormFields.forEach(location => {
      this.appLocations.controls.push(this.formBuilder.group({[location]: false, location}));
    });

    appLocationFormFields.forEach((field, index) => {
      if (this.selectedApp.supportedLocation === field.toUpperCase() || this.selectedApp.supportedLocation === 'ALL') {
        this.appLocations.controls[index].get(field).setValue(true);
      }
    });
  }

  private disableFormFields() {
    this.appAccessTypes.controls.forEach(control => control.disable());
    this.appLocations.controls.forEach(control => control.disable());

    this.clientProvisioningRequired.disable();
    this.exposesMemberData.disable();
    this.usesMasking.disable();
    this.isActive.disable();
  }

  openCancelConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Confirmation',
        body:
          '<br>'
          + 'Are you sure you want to discard any changes made to this page?'
          + '<br>'
          + '<br>'
          + 'Warning: This cannot be undone.'
          + '<br><br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.resetForm();
      this.appOverviewChange.emit(false);
    });
  }

  resetForm() {
    this.appAccessTypes.clear();
    this.appLocations.clear();
    this.addAppForm.reset();
    this.initForm();
  }

  openTabChangedConfirmationModal(){
    this.tabChangedConfirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent,{
      data:{
        headline:'Leave page without saving?',
        body:'<br>' +
          'You have unsaved changes, are you sure you want to leave this page ' + '<br>' + ' without saving?' +
          '<br><br>' +
          'If you leave this page, any changes will be lost.' +
          '<br><br>',
        actionLabel:'Discard Changes',
        cancelLabel:'Continue Editing',
        noCloseX: false
      }
    });

    this.tabChangedConfirmationDialog.componentInstance.onConfirmClick.subscribe(() =>{
      this.resetForm();
      this.appOverviewChange.emit(false);
      this.parentComponent.selectedTabIndex = this.previouslySelectedTabIndex;
    });

    this.tabChangedConfirmationDialog.componentInstance.onCancelClick.subscribe(() =>{
      this.tabChangedConfirmationDialog.close();
    });

  }

  onFormValueChange(){
    this.addAppForm.valueChanges.subscribe(value => {
      this.appOverviewChange.emit(true);
    });
  }

  onAppAccessTypeChanged(event: any){
    this.appOverviewChange.emit(true);
  }

  onAppLocationChanged(event: any){
    this.appOverviewChange.emit(true);
  }

  onSubmit() {
    this.showSpinner.next(true);
    const updateAppRequest: PBMAppInfo = this.buildUpdateAppRequest();
    this.appManagementService.updateApp(updateAppRequest).subscribe({
      next: this.handleUpdateSuccess.bind(this),
      error: this.handleUpdateFailure.bind(this)
    });
  }

  handleUpdateSuccess(updatedPBMAppInfo: PBMAppInfo) {
    this.appDataService.setCurrentApp(updatedPBMAppInfo);
    this.selectedApp = updatedPBMAppInfo;
    this.snackBarWrapperService.postCenterBottomSuccessSnackBar('Changes have been successfully saved.');
    this.showSpinner.next(false);
    this.appOverviewChange.emit(false);
  }

  handleUpdateFailure() {
    this.showSpinner.next(false);
  }

  private buildUpdateAppRequest(): PBMAppInfo {
    const updateAppRequest: PBMAppInfo = {
      id: this.selectedApp.id,
      appCode: this.selectedApp.appCode,
      appName: this.selectedApp.appName,
      appDescription: this.selectedApp.appDescription,
      appShortName: this.selectedApp.appShortName,
      appIcon: this.selectedApp.appIcon,
      appUrl: this.selectedApp.appUrl,
      appDomain: this.selectedApp.appDomain,
      roles: this.selectedApp.roles,
      permissions: this.selectedApp.permissions,
    };

    updateAppRequest.active = this.isActive.value;
    updateAppRequest.exposesMemberData = this.exposesMemberData.value;
    updateAppRequest.clientProvisioningRequired = this.clientProvisioningRequired.value;
    updateAppRequest.usesMasking = this.usesMasking.value;

    updateAppRequest.supportedLocation = this.mapSupportedLocationToPBMAppInfo();
    updateAppRequest.appAccessTypes = this.mapAppAccessTypeToPBMAppInfo();

    return updateAppRequest;
  }

  private mapAppAccessTypeToPBMAppInfo(): PBMAccessTypeInfo[] {
    const PBMAccessTypeInfoList: PBMAccessTypeInfo[] = [];

    for (const appAccessTypesControl of this.appAccessTypes.controls) {
      const appAccessTypesValue = appAccessTypesControl.value['accessType'];

      if (appAccessTypesControl.value[appAccessTypesValue]) {
        PBMAccessTypeInfoList.push({accessType: this.controlNameToLabelMap[appAccessTypesValue]});
      }
    }

    return PBMAccessTypeInfoList;
  }

  private mapSupportedLocationToPBMAppInfo(): string {
    let offshore = false;
    let onshore = false;

    for (const appLocationControl of this.appLocations.controls) {
      const appLocationValue = appLocationControl.value;

      if (appLocationValue['location'] === UserLocationEnum.OFFSHORE && appLocationValue[UserLocationEnum.OFFSHORE] === true) {
        offshore = true;
      }

      if (appLocationValue['location'] === UserLocationEnum.ONSHORE && appLocationValue[UserLocationEnum.ONSHORE] === true) {
        onshore = true;
      }
    }

    if (offshore && onshore) {
      // TODO: 3/15/23 NC - Either include ALL in enum or fix the table structure to better support multiple locations
      return 'ALL';
    } else if (offshore) {
      return UserLocationEnum.OFFSHORE.toUpperCase();
    } else {
      return UserLocationEnum.ONSHORE.toUpperCase();
    }
  }

  get appAccessTypes() {
    return this.addAppForm.get('appAccessTypes') as UntypedFormArray;
  }

  get appLocations() {
    return this.addAppForm.get('appLocations') as UntypedFormArray;
  }

  get clientProvisioningRequired() {
    return this.addAppForm.get('clientProvisioningRequired') as UntypedFormControl;
  }

  get exposesMemberData() {
    return this.addAppForm.get('exposesMemberData') as UntypedFormControl;
  }

  get usesMasking() {
    return this.addAppForm.get('usesMasking') as UntypedFormControl;
  }

  get isActive() {
    return this.addAppForm.get('isActive') as UntypedFormControl;
  }
}
