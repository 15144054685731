<div class="add-user">
  <div style="padding-bottom: 29px">
    <button id="back-button-user-add-edit"
      class="pds-btn-text-only back-button" routerLink="/user-management/users">
      <mat-icon svgIcon="caret-left--s" class="back-button-icon"></mat-icon>
      Back
    </button>
  </div>
  <div class="add-user__title">
    <h1 id="title">{{title}}</h1>
    <p id="titleHelpText">{{titleHelpText}}</p>
  </div>
  <div id="addUserBanner"></div>

  <cvs-loader-overlay-container [isLoading]="(showSpinner | async)"
                                [opacity]=".7"
                                loadingMessage="Loading">
    <div class="loader">
      <form [formGroup]="addUserForm" class="add-user__form" [ngClass]="{'adjustHeight': showSpinner | async}" (ngSubmit)="onSubmit()"
            (keydown.enter)="$event.preventDefault()">
        <h2 id="userInformationId">{{cardTitle}}</h2>
        <div [ngSwitch]="userType.value" *ngIf="isCopyMode()" class="copy-user-text">
          <div *ngSwitchCase="UserAccessType.CVS_HEALTH_COLLEAGUE">
            Copying from {{userBeingEditedOrCopied.basicUserInfo.firstName}} {{userBeingEditedOrCopied.basicUserInfo.lastName}}
            will include User Type, User Access, App Access, Client Access, Client Group Access, and General Access.
          </div>
          <div *ngSwitchCase="UserAccessType.CONSULTANT_USER">
            Copying from {{userBeingEditedOrCopied.basicUserInfo.firstName}} {{userBeingEditedOrCopied.basicUserInfo.lastName}}
            will include User Type, Audit Firm, App Access, Client Access, and Client Group Access.
          </div>
          <div *ngSwitchCase="UserAccessType.CLIENT_USER">
            Copying from {{userBeingEditedOrCopied.basicUserInfo.firstName}} {{userBeingEditedOrCopied.basicUserInfo.lastName}}
            will include User Type, App Access, Client Access, and Client Group Access.
          </div>
        </div>

        <div class="add-user__form__user-type" *ngIf="!isEditMode()">
          <h3 id="userTypeTitle">{{userTypeTitle}}</h3>
          <mat-form-field class="user-type-field" hideRequiredMarker="true">
            <mat-select id="userTypeSelection" placeholder="Select option"
                        formControlName="userType" (selectionChange)="changeUserType($event)"
                        [ngClass]="{'group-red-outline': isFormControlInValid(userType)}">
              <mat-option [value]="option" *ngFor="let option of userTypeToggleOptions">
                {{option}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container id="userTypeErrorMessage" *ngIf="isFormControlInValid(userType)">
            <div id="userTypeRequired" class="pbm-error-text" *ngIf="userType.errors?.['required']">
              User type required.
            </div>
          </ng-container>
        </div>

        <div *ngIf="isEditMode()" class="edit-user__form__user-type">
          <h3>{{addUserForm.controls['userType'].value}}</h3>
        </div>

        <div *ngIf="userType.value">
          <h3 id="basicDetailSubtitle" class="add-user__basic-detail-header">{{basicDetailSubtitle}}</h3>
          <div class="section-row">
            <mat-form-field hideRequiredMarker="true" class="short-field">
              <input matInput placeholder="First Name" formControlName="firstName" required maxlength="25">
              <mat-error id="firstNameRequired"
                         *ngIf="isFormControlInValid(firstName) && (firstName.errors?.['required'] || firstName.errors?.noWhiteSpace)">
                First name required.
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Middle Name" formControlName="middleName">
            </mat-form-field>
            <mat-form-field hideRequiredMarker="true" class="short-field">
              <input matInput placeholder="Last Name" formControlName="lastName" required maxlength="25">
              <mat-error id="lastNameRequired"
                         *ngIf="isFormControlInValid(lastName) && (lastName.errors?.['required'] || lastName.errors?.noWhiteSpace)">
                Last name required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="section-row">
            <mat-form-field id=emailField hideRequiredMarker="true" [ngClass]="makeEmailFieldReadOnly()">
              <input matInput placeholder="Email Address" formControlName="email" required type="email" maxlength="60">
              <mat-error id="emailRequired" *ngIf="isFormControlInValid(email) && email.errors?.['required']">
                Email address required.
              </mat-error>
              <mat-error id="emailFormat"
                         *ngIf="isFormControlInValid(email) && (email.errors?.['email'] || email.errors?.['emailNotFound'])">
                Valid email address required.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="short-field">
              <input matInput placeholder="Phone Number (Optional)" formControlName="phone" type="tel" maxlength="10">
              <mat-hint *ngIf="!phone.errors?.['pattern']">1234567890</mat-hint>
              <mat-error id="phonePattern" *ngIf="isFormControlInValid(phone) && phone.errors?.['pattern']">
                Valid phone number required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="section-row">
            <ng-container *ngIf="isInternalUser()">
              <mat-form-field hideRequiredMarker="true" class="short-field">
                <input matInput placeholder="Employee ID" formControlName="employeeId" maxlength="7">
                <mat-hint *ngIf="!isFormControlInValid(employeeId)">7 digit number</mat-hint>
                <mat-error id="employeeIdRequired"
                           *ngIf="isFormControlInValid(employeeId) && employeeId.errors?.['required']">
                  Employee ID required.
                </mat-error>
                <mat-error id="employeeIdPattern"
                           *ngIf="isFormControlInValid(employeeId) && employeeId.errors?.['pattern']">
                  Employee ID must be 7 digits.
                </mat-error>
                <mat-error id="employeeIdNotUnique"
                           *ngIf="isFormControlInValid(employeeId) && employeeId.errors?.['notUnique']">
                  Unique Employee ID required.
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="isInternalUser()">
              <mat-form-field hideRequiredMarker="true" class="short-field">
                <mat-select placeholder="Colleague Affiliation" formControlName="corporation">
                  <mat-option *ngFor="let corp of corporationOptions" [value]="corp">
                    {{corp}}
                  </mat-option>
                </mat-select>
                <mat-error id="corporationRequired"
                           *ngIf="isFormControlInValid(corporation) && corporation.errors?.['required']">
                  Colleague Affiliation required.
                </mat-error>
              </mat-form-field>
            </ng-container>
            <div class="wrapper-div">
              <ng-container>
                <div class="add-user__form__location">
                  <mat-form-field class="user-location-field" hideRequiredMarker="true">
                    <mat-select id="userLocationSelection" placeholder="Location"
                                formControlName="userLocation"
                                [ngClass]="{'group-red-outline': isFormControlInValid(userLocation)}">
                      <mat-option [value]="option" *ngFor="let option of userLocationToggleOptions">
                        {{option}}
                      </mat-option>
                    </mat-select>
                    <mat-error id="locationRequired" class="pbm-error-text"
                               *ngIf="isFormControlInValid(userLocation) && userLocation.errors?.['required']">
                      {{myLocationError}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-container *ngIf="userBeingEditedOrCopied; else showActiveLabel">
                <div>
                  <mat-form-field hideRequiredMarker="true" class="edit-user__form__active short-field">
                    <mat-select (selectionChange)="statusSelectionChange($event)" placeholder="Status"
                                formControlName="active">
                      <mat-option *ngFor="let option of activeOptions" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-template #showActiveLabel>
                <ng-container>
                  <div class="add-user__form__active">
                    <div class="pds-label-text">Status</div>
                    <div>Active</div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-container *ngIf="isConsultantUser()">
                <div class="add-user__form__firm">
                  <mat-form-field class="user-firm-field" hideRequiredMarker="true">
                    <input type="text"
                           placeholder="Select Firm (Optional)"
                           aria-label="Firm"
                           matInput
                           #autocompleteInput="matInput"
                           #firmInput
                           formControlName="userFirm"
                           [formControl]="this.userFirm"
                           [ngClass]="{'group-red-outline': isFormControlInValid(userFirm)}"
                           [matAutocomplete]="firmAuto">
                    <button class="dropdown-arrow" matSuffix mat-icon-button
                            aria-label="Dropdown Arrow" (click)="$event.preventDefault(); autocompleteInput.focus()"
                            *ngIf="!this.userFirm.value">
                      <mat-icon svgIcon="disclosure--s" [ngClass]="{'focused-arrow': autocompleteInput.focused}"
                                aria-label="Arrow">
                      </mat-icon>
                    </button>
                    <button id="clear-firm-button" matSuffix mat-icon-button
                            aria-label="Clear" (click)="clearFirm($event)"
                            *ngIf="this.userFirm.value">
                      <mat-icon svgIcon="close-btn--s"
                                aria-label="Clear Firm" aria-hidden="false"></mat-icon>
                    </button>
                    <mat-hint align="start">
                      Audit management App is required with Firm selection (Optional)
                    </mat-hint>
                    <mat-autocomplete #firmAuto="matAutocomplete" (optionSelected)="updateFirmDropdownOnOptionSelected()"
                                      autoActiveFirstOption>
                      <mat-option [value]="option" *ngFor="let option of filteredFirmOptions | async">{{option}}</mat-option>
                    </mat-autocomplete>
                    <mat-error id="firmRequired" class="pbm-error-text"
                               *ngIf="isFormControlInValid(userFirm) && userFirm.errors?.['required']">
                      <mat-icon class="user-app-access-error-icon firm-error " svgIcon="warning-f--xs"> </mat-icon>
                      {{myFirmError}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>

            <h2 class="add-user__basic-detail-header">{{userAccessSubtitle}}</h2>
            <div *ngIf="isAdmin && isInternalUser()" class="admin-type-div header-padding">
              <mat-checkbox class="admin-checkbox" formControlName="adminUser">
                myPBM Admin
              </mat-checkbox>
              <mat-checkbox class="client-admin-checkbox" id="client-admin-checkbox" formControlName="clientAdminUser">
                Client Admin
              </mat-checkbox>
            </div>

            <mat-tab-group class="pds-primary-tab" animationDuration="0ms" disableRipple color="primary"
                           [(selectedIndex)]="userAccessTabIndex">
              <mat-tab class="tab-label">
                <ng-template mat-tab-label>
                  <label>App Access</label>
                </ng-template>

                <ng-container *ngIf="userType && userType.value !== null">
                  <div class="add-user__form__app-access">
                    <div formArrayName="userAppAccess">
                      <mat-nav-list>

                        <ng-container *ngFor="let appFg of userAppAccess.controls; let i=index"
                                      class="add-user__form__app-access__item">

                        <ng-container [formGroup]="appFg">

                            <div *ngIf="userAccessTypesContainValue(appFg.get('appAccessTypes'), userType.value) ||
                          userAccessTypesContainValue(appFg.get('appAccessTypes'), 'ALL')" class="app-toggle-group-{{i}} app-access-div" tabindex="0">

                            <mat-expansion-panel class="app-access-expansion-panels" [expanded]="appFg.get('appSelected').value && appFg.touched"
                                                 [ngClass]="{'app-access-red-border': isFormControlInValid(appFg.get('appSelected'))}" #appAccessExpansionPanel>
                              <mat-expansion-panel-header>
                                <mat-checkbox [id]="'appSelectionCheckbox' + i"
                                              class="appSelectionCheckboxes"
                                              formControlName="appSelected"
                                              (change)="appAccessCheckBoxSelection($event, appFg)"
                                              (keydown.space)="markFormAsTouched($event, appFg)"
                                              (click)="markFormAsTouched($event, appFg)"
                                              [disabled]="!isAdmin && containsAppAdminRole(appFg)">
                                </mat-checkbox>
                                <h3>{{appFg.get('appName').value}}</h3>
                              </mat-expansion-panel-header>
                              <div>
                                <div class="role-access-text-and-error">
                                  <h4 id="role-access-text">Role Access</h4>
                                  <div class="role-access-error-text" *ngIf="appFg.get('appSelected').errors?.required && formSubmitAttempt">At least one role is required.</div>
                                </div>
                                    <app-user-app-access-role-grid
                                            [userBeingEditedOrCopied]="userBeingEditedOrCopied"
                                            [gridInputData]="appFg.value"
                                            [userTypeValue]="this.userType.value"
                                            [appFgControls]="appFg.controls"
                                            [adminUserValue]="adminUser.value"
                                            [firmValue]="userFirm.value"
                                            [cdr]="cdr"
                                            [currentUser]="this.currentUserService.currentUser$.value">
                                    </app-user-app-access-role-grid>
                              </div>
                            </mat-expansion-panel>
                            <mat-error class="user-app-access-error" *ngIf="appFg.get('appSelected').errors?.required && formSubmitAttempt">
                              <mat-icon class="user-app-access-error-icon" svgIcon="warning-f--xs"></mat-icon>
                              Role Access for {{appFg.get('appName').value}} is required.
                            </mat-error>
                          </div>
                        </ng-container>
                      </ng-container>
                    </mat-nav-list>
                  </div>
                </div>
              </ng-container>
            </mat-tab>


              <mat-tab class="tab-label">
                <ng-template mat-tab-label>
                  <label>Client Access</label>
                </ng-template>

                <app-user-client-access
                  [userBeingEditedOrCopied]="userBeingEditedOrCopied"
                  [addUserForm]="addUserForm"
                  [gridInputData]="usersClientAccess.value"
                >

                </app-user-client-access>
              </mat-tab>

              <mat-tab class="tab-label">
                <ng-template mat-tab-label>
                  <label>Client Group Access</label>
                </ng-template>
                <app-user-client-group-access
                  [userBeingEdited]="userBeingEditedOrCopied"
                  [addUserForm]="addUserForm"
                >
                </app-user-client-group-access>
              </mat-tab>

              <mat-tab class="tab-label" *ngIf="isInternalUser()">
                <ng-template mat-tab-label>
                  <label>General Access</label>
                </ng-template>
                <app-user-general-access-grid
                  [addUserFormControl]="addUserForm.controls"
                  [formSubmitted]="formSubmitAttempt">
                </app-user-general-access-grid>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div class="add-user__form__buttons">
            <button id="saveUserBtn" *ngIf="isEditMode() && addUserForm.dirty"
                    class="pds-btn-primary" type="submit">
              Save Changes
            </button>
            <button *ngIf="!isEditMode()" id="addUserBtn" type="submit" class="pds-btn-primary">
              Add User
            </button>
            <button class="pds-btn-ghost" *ngIf="addUserForm.dirty" (click)="exitAddUser()" type="button"> Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </cvs-loader-overlay-container>
</div>
