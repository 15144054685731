import {Component, ViewEncapsulation} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Router} from '@angular/router';
import {AddEditModeEnum} from '../../../enums/add-edit-form.enum';
import {UserDataService} from '../../../services/user-management/data/user-data.service';

@Component({
  selector: 'app-user-management-table-action-cell-renderer',
  templateUrl: './user-management-table-action-cell-renderer.component.html',
  styleUrls: ['./user-management-table-action-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserManagementTableActionCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  isAllowedToCopyMyPBMAdmin = false;
  mode: AddEditModeEnum;

  constructor(
    private router: Router,
    private userDataService: UserDataService,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isAllowedToCopyMyPBMAdmin = this.checkIfAllowedToCopyMyPBMAdmin();
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  navigateToAddEditUserManagement() {
    this.router.navigate(['/user-management/users/edit', this.params.data.basicUserInfo?.email], {state: {mode: AddEditModeEnum.EDIT}});
  }

  navigateToCopy() {
    this.userDataService.setUserBeingCopied(this.params.data);
    this.router.navigate(['/user-management/users/add'], {
      state: {
        mode: AddEditModeEnum.ADD,
      }
    });
  }

  checkIfAllowedToCopyMyPBMAdmin(): boolean {
    const isCurrentUserMyPBMAdmin = this.params.context.this.currentUser.adminTypes.some((adminType: string) => adminType === 'myPBM');
    const isCurrentRowMyPBMAdmin = this.params.data.adminTypes.some((adminType: string) => adminType === 'myPBM');

    if (isCurrentUserMyPBMAdmin) {
      return true;
    } else {
      return !isCurrentRowMyPBMAdmin;
    }
  }
}
