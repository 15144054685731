<div class="client-dropdown">
  <mat-form-field class="client-select">
    <mat-label aria-label ="Selected Client Option Field">Select Client Option</mat-label>
    <mat-select id="clientOption"  [disableOptionCentering]="true"
                [formControl]="clientReportOption" (selectionChange)='onClientDropDownChange()'>
      <mat-option *ngFor="let clientReportOption of clientReportOptions;"
                  [value]="clientReportOption">
        {{clientReportOption.label}}
      </mat-option>
    </mat-select>
    <mat-error data-cy="client-option-required-error"
                *ngIf="clientReportOption.errors?.required">
      <mat-icon class="error-icon" svgIcon="warning-f--xs"></mat-icon>
      Client Option required
    </mat-error>
  </mat-form-field>
  <app-client-search #clientSearch
                     *ngIf="isClientSearchShown"
                     class="report-client-search"
                     style="display: inline-block; margin-left: 19px;"
                     [buttonClass]="'pds-btn-ghost'"
                     [clientSearchType]="clientSearchType"
                     (searchOutput)="onSearch($event)"
                     (validationError)="onClientSearchValidationError()">
  </app-client-search>

  <button *ngIf="isButtonShown"
          id="clear-results"
          aria-label="Clear Results Button"
          mat-button color="primary"
          type="button"
          (click)="reset()">
    Clear Results
  </button>

  <div class="client-error-text-message" *ngIf="isClientNotFoundErrorMessageShown">
    No client(s) to display, search again or contact your client administrator to add the client.
  </div>
</div>

<div [tabindex]="0"  aria-label="Search Results Data Table" class="client-profile-clients-grid-container tile__background-border" *ngIf="clients">
  <h2 [tabIndex]="0" aria-label="Search Results Header" class="client-codes-header-padding"> Search Results ({{this.clients.length}} of {{totalClients}})</h2>
  <p [tabIndex]="0" class="client-access-expansion-panel-sub-title">Here are the results from the search criteria, select the
    client and click the Export Report button to generate a report.</p>
  <ul>
    <mat-radio-group class="pds-radio-group--vertical">
      <li *ngFor="let client of clients">
        <mat-radio-button class="client-access-checkbox"
                          [value]="client"
                          (keydown.enter)="$event.preventDefault()"
                          (change)="onClientSelect($event)"
                          [aria-label]="'Selected radio button for ' +
                          (lastSearchedClientSearchType === ClientSearchType.SUPER_CLIENT_ID || lastSearchedClientSearchType === ClientSearchType.SUPER_CLIENT_NAME ? client.superClientName + client.superClientId :
                          lastSearchedClientSearchType === ClientSearchType.CLIENT_CODE ? client.clientCode :
                          lastSearchedClientSearchType === ClientSearchType.CARRIER_ID || lastSearchedClientSearchType === ClientSearchType.CARRIER_NAME ? client.carrierName + client.carrierId :
                          lastSearchedClientSearchType === ClientSearchType.SALESFORCE_ACCOUNT_NAME ? client.accountName + client.accountId :
                          lastSearchedClientSearchType === ClientSearchType.SALESFORCE_ACCOUNT_ID ? client.accountId + client.accountName : null)">
        </mat-radio-button>
        <a [tabIndex]="0"
           [attr.aria-label]="'Hyperlink for ' + (client.superClientName + client.superClientId)"
           *ngIf="lastSearchedClientSearchType === ClientSearchType.SUPER_CLIENT_ID || lastSearchedClientSearchType === ClientSearchType.SUPER_CLIENT_NAME"
           (click)="openClientProfileModal(client)"
           (keyup.space)="openClientProfileModal(client)">{{client.superClientName}} ({{client.superClientId}})</a>
        <a [attr.aria-label]="'Hyperlink for ' + (client.clientCode)" [tabIndex]="0"
           *ngIf="lastSearchedClientSearchType === ClientSearchType.CLIENT_CODE"
           (click)="openClientProfileModal(client)"
           (keyup.space)="openClientProfileModal(client)">{{client.clientCode}}</a>
        <a [attr.aria-label]="'Hyperlink for ' + (client.carrierName + client.carrierId)" [tabIndex]="0"
           *ngIf="lastSearchedClientSearchType === ClientSearchType.CARRIER_ID || lastSearchedClientSearchType === ClientSearchType.CARRIER_NAME"
           (click)="openClientProfileModal(client, true)"
           (keyup.space)="openClientProfileModal(client, true)">{{client.carrierName}}
          ({{client.carrierId}})</a>
        <a [attr.aria-label]="'Hyperlink for ' + client.accountName + client.accountId" [tabIndex]="0"
           *ngIf="lastSearchedClientSearchType === ClientSearchType.SALESFORCE_ACCOUNT_NAME"
           (click)="openClientProfileModal(client)"
           (keyup.space)="openClientProfileModal(client)">{{client.accountName}}
          ({{client.accountId}})</a>
        <a [attr.aria-label]="'Hyperlink for ' + client.accountId + client.accountName" [tabIndex]="0"
           *ngIf="lastSearchedClientSearchType === ClientSearchType.SALESFORCE_ACCOUNT_ID"
           (click)="openClientProfileModal(client)"
           (keyup.space)="openClientProfileModal(client)">{{client.accountId}}
          ({{client.accountName}})</a>
      </li>
    </mat-radio-group>
  </ul>
  <app-client-paginator
    #clientsPaginator
    [totalRowCount]="totalClients"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 15, 20]"
    (page)="handlePageEvent($event)">
  </app-client-paginator>
</div>
