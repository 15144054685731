<div class="search-bar-container">
    <div class="search-bar">
        <mat-form-field appearance="legacy">
            <input matInput placeholder="Search" [(ngModel)]="searchValue" (ngModelChange)="updateFilter()" (keyup)="onKeyUp()" />
        </mat-form-field>
        <mat-icon svgIcon="search--s" *ngIf="!searchValue"></mat-icon>
        <mat-icon svgIcon="close-btn--s" aria-hidden="false" [tabIndex]="0" *ngIf="searchValue" (click)="clearText()"
                  (keydown.enter)="clearText()" aria-label="Clear Search"></mat-icon>
    </div>
</div>
