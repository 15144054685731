import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {CurrentUserService} from '../../services/current-user/current-user.service';
import {PBMUserInfo} from '../../models/User';

@Injectable({
  providedIn: 'root'
})
export class AdminTypeGuard  {
  currentUser: PBMUserInfo;

  constructor(private currentUserService: CurrentUserService) {
    this.currentUserService.currentUser$.subscribe((user: PBMUserInfo) => this.currentUser = user);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.verifyAdminType(route);
  }

  verifyAdminType(route: ActivatedRouteSnapshot): boolean {
    if (this.currentUser && this.currentUser.adminTypes?.length) {
      if (route.data['adminTypes'] &&
        Object.values(this.currentUser.adminTypes).some((adminType) => route.data['adminTypes'].includes(adminType))) {
        return true;
      }
    }
    return false;
  }
}
