<div class="dialog-wrapper">
  <div mat-dialog-content class="dialog-header">
    <div class="header-icons">
      <img style="margin-bottom: 12px; " class="modal-icon" alt="error" height="24" src="https://sapbmappspkmypbmdev000.blob.core.windows.net/mypbm-dev-container/svgs/Error.svg">
      <button *ngIf="!data.noCloseX" id="closeAction" class="pds-icon-only-btn" mat-dialog-close aria-label="Close">
        <mat-icon svgIcon="close-btn--s" aria-hidden="false"></mat-icon>
      </button>
    </div>

    <h2 id="headlineValue">{{data.headline}}</h2>
  </div>

  <div mat-dialog-content class="dialog-main">
    <div *ngIf="!data.privacy; else showPrivacy" style="line-height: 21px">
      <div>
        <p>Last Updated: June 6, 2024</p>
        <p>
          Please read this Agreement carefully and completely before using myPBM™, currently located at mypbm.caremark.com, (the “Site”) or any services or information provided through or in connection with the Site or by CVS Caremark or one of its subsidiaries or affiliates (together with the Site, collectively, the “Service”). By clicking “I Accept” to this Agreement, or by using the Service (including any access to the Service), you expressly agree to be bound by this Agreement, by and between you and CVS Caremark (“CVS Caremark,” “us” or “we”), which incorporates by this reference any additional terms and conditions posted by CVS Caremark through the Site, or otherwise made available to you by CVS Caremark. This Agreement does not govern your use of other sites or apps (including services on other sites or apps) operated by CVS Caremark or its affiliates, such as www.caremark.com (the use of which is governed by separate Terms of Use currently available
          <a target="_blank" href="https://www.caremark.com/wps/portal/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zinSzMzS28gwxDQzwsLQ08XS2MAy1cvAwMDIz1wwkpiMIvHUlIf0FuRCUAAND1WQ!!/" rel=noopener>here</a>), www.cvshealth.com (the use of which is governed by separate Terms of Use currently available
          <a target="_blank" href="https://www.cvshealth.com/terms-of-use" rel=noopener>here</a>). The information and resources contained on and accessible through the Service are made available by CVS Caremark and its suppliers and vendors, and other third parties, to certain of its clients and third party consultants, including the client that employs or contracts you (“Your Company”) subject to your agreement to the terms and conditions of this Agreement as well as the underlying contractual provisions between CVS Caremark and Your Company.
        </p>
      </div>
      <div id="content" *ngFor="let text of data.content; let i = index" [ngClass]="{'textheading': text.length <= 76 }" [ngSwitch]="i">
        <p *ngSwitchCase="23">
          Your submission of information through the Services is governed by our applicable
          <a target="_blank" href="https://www.cvshealth.com/privacy-policy" rel=noopener>Privacy Policy</a>,
          which, among other things, explains how we seek to protect the privacy of the personal information that you provided to us through the Service. If you give us the personal information of others in connection with the Service, you represent that you have the right to do so. And you permit us to use the information in accordance with the Privacy Policy. CVS Caremark may disclose your information to comply with applicable rules, orders, subpoenas or other legal process, or in order to give information to any government agency or official requesting such information.
        </p>
        <p *ngSwitchDefault>{{text}}</p>
      </div>
    </div>
    <ng-template #showPrivacy>
      <div style="line-height: 21px">
        <div>
          <p>Effective Date: June 6, 2024</p>
        </div>
        <div id="content" *ngFor="let text of data.content; let i = index" [ngClass]="{'textheading': text.length < 50 && i < 49}" [ngSwitch]="i">
          <p *ngSwitchCase="3" style="font-weight: bold">
            Our Service is not intended for children under the age of 18, and therefore, we do not knowingly collect personal information online from any person we know to be under the age of 18 and instruct users under 18 not to send us any information to or through the online Service.
          </p>
          <ul *ngSwitchCase="7">
            <li><strong>Registration:</strong> We collect your personal information when you register on the Services.</li>
            <li><strong>Contact Information:</strong> We collect personal information from users of the Services.</li>
            <li><strong>Device Information:</strong> We may automatically collect certain personal device information such as device locale, cellular carrier, device manufacturer and device model for purposes of diagnosing problems and to ensure services function properly.</li>
          </ul>
          <p *ngSwitchCase="44">
            By establishing a <a target="_blank" href="https://mypbm.caremark.com" rel=noopener>myPBM</a>™ account, you agree that it is your responsibility to:
          </p>
          <ul *ngSwitchCase="45">
            <li>Authorize, monitor, and control access to and use of your myPBM™ account, User ID and password.</li>
            <li>Promptly inform us of any need to deactivate a password or an account by calling Customer Service at (800) 841-5550.</li>
          </ul>
          <p *ngSwitchCase="49">
            CVS Health <br>
            Attn: Privacy Office <br>
            1 CVS Drive <br>
            Woonsocket, R.I. 02895 <br>
            (800) 841-5550
          </p>
          <p *ngSwitchDefault>{{text}}</p>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="dialog-footer">
    <div class="dialog-footer__row" *ngIf="data.source === 'Login'">
      <div class="dialog-footer__buttons">
        <button id="continueBtn" class="cvs-blue-btn-primary" (click)="continueClick()">{{ data.actionLabel }}</button>
        <button id="cancelBtn" class="cvs-blue-btn-white" (click)="cancelClick()">{{ data.cancelLabel }}</button>
      </div>
    </div>
    <div class="dialog-footer__row right_align" *ngIf="data.source === 'Landing'">
      <div class="dialog-footer__buttons">
        <button id="closeBtn" class="cvs-blue-btn-primary" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</div>
