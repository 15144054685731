<button class="pds-icon-only-btn"
        aria-label="Open action menu"
        [matMenuTriggerFor]="menu"
        *ngIf="!params.node.group"
        (click)="setTableActionClicked(true)"
        (menuClosed)="setTableActionClicked(false)">
  <mat-icon svgIcon="more-vert-f--s" role="img"></mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
  <div>
    <button class="pds-btn-text-only action-menu-btn" (click)="navigateToAddEditPermission()" mat-menu-item>
      <mat-icon svgIcon="pencil--s"></mat-icon>
      Edit
    </button>
  </div>
  <div>
    <button class="pds-btn-text-only action-menu-btn"
            (click)="openDeleteConfirmationModal()"
            mat-menu-item>
      <mat-icon svgIcon="trash--s"></mat-icon>
      Delete
    </button>
  </div>
  <div>
    <button class="pds-btn-text-only action-menu-btn"
            *ngIf="showEnabledAction"
            (click)="openEnableConfirmationModal()"
            mat-menu-item>
      <mat-icon svgIcon="check-circle--s"></mat-icon>
      Activate
    </button>
    <button class="pds-btn-text-only action-menu-btn"
            *ngIf="showDisabledAction"
            (click)="openDisableConfirmationModal()"
            mat-menu-item>
      <mat-icon svgIcon="not-allowed--s"></mat-icon>
      Inactivate
    </button>
  </div>
</mat-menu>

