import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {BrandTypes, CVSAuthConfig, CVSBaseConfig, MenuState, SideNavConfig} from 'angular-component-library';
import {BehaviorSubject, catchError, Observable, of, skip, Subject, take, takeUntil, timeout} from 'rxjs';
import {environment} from 'src/environments/environment';
import {FooterComponent} from './footer/footer.component';
import {UnsavedChangesGuard} from './guards/unsaved-changes/unsaved-changes.guard';
import {CurrentUserService} from './services/current-user/current-user.service';
import {PBMUserInfo} from './models/User';
import {AuditLogService} from './audit-log/audit-log.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivationEnd, Router} from '@angular/router';
import {AdminTypeEnum} from './enums/admin-type.enum';
import {AppManagementService} from './services/app-management/app-management.service';
import { PageLoadEvent, TaggingService } from '@pbm-shared/pbm-shared-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
  baseConfig: CVSBaseConfig;
  authConfig: CVSAuthConfig;
  sideNavConfig: SideNavConfig;
  destroy$ = new Subject();
  showSpinner: Subject<boolean> = new BehaviorSubject(true);
  currentUser: PBMUserInfo;
  title = 'portal-web';
  private isFreshLogin: boolean;

  constructor(private currentUserService: CurrentUserService,
              private unsavedChangesGuardService: UnsavedChangesGuard,
              private auditLogService: AuditLogService,
              private appManagementService: AppManagementService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private taggingService: TaggingService,
              private titleService: Title,
              public router: Router
  ) {
    const appNameTitle = 'myPBM™ '.concat(environment.region.includes('Prod') ? '' : environment.region);
    this.baseConfig = {
      homeRoute: '/',
      appName: appNameTitle,
      unbranded: false,
      brand: BrandTypes.CAREMARK,
      footerPortal: FooterComponent,
      footerText: this.getFooterText(),
      logoutCallback: this.logout,
    } as CVSBaseConfig;
    this.isFreshLogin = !!(window.location.href.indexOf('?state=') > -1);
    this.authConfig = environment.authConfig;
    this.matIconRegistry
      .addSvgIcon('expand', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/expand.svg'))
      .addSvgIcon('collapse', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/collapse.svg'));

    router.events.subscribe(event => {
      if (event instanceof ActivationEnd && event.snapshot.data['title']) {
        if (event.snapshot.params?.appCode) {
          this.titleService.setTitle(event.snapshot.data['title'].replace(':appCode', event.snapshot.params?.appCode));
        } else {
          this.titleService.setTitle(event.snapshot.data['title']);
        }
      }
    });
    if (environment.region !== '- Local' && environment.region !== '- LOCAL') {
      this.loadMonitoringScripts();
    }
  }

  getFullYear = (): number => new Date().getFullYear();
  getFooterText = (): string => '© Copyright 2022-' + this.getFullYear() + ' CVS Caremark. All rights reserved';

  getThis = (): string => '<h2>hello</h2>';

  ngOnInit() {
    this.showSpinner.next(true);
    this.currentUserService.currentUser$
      .pipe(
        skip(1),
        timeout({first: 60000}),
        catchError(error => of('Request timed out')),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (user: any) => {
          this.currentUser = user;
          if(this.isFreshLogin) {
            this.auditLogService.postAuditLog('login', '');
          }
          this.setSideNavConfig(user);
          this.displayPage();
          this.showSpinner.next(false);
        },
        error: () => {
          this.sideNavConfig = undefined;
          this.showSpinner.next(false);
        }
      });
  }

  ngAfterViewInit() {
    if (null != environment.adobeAnalyticsUrl) {
      this.taggingService.pageLoad({
        pageName: 'mypbm|portal|apps_screen',
        pageDetail: 'apps_screen',
        pageCategory: 'mypbm|portal',
        criteriaStatus: 'na',
        drugType: 'na',
        referenceId: 'na'
      } as PageLoadEvent);
    }
  }

  private setSideNavConfig(user: any) {
    this.sideNavConfig = undefined;
    if (user && user.adminTypes?.length) {
      this.sideNavConfig = {
        sideNavItems: [
          ...user.adminTypes.some(adminType =>
            [AdminTypeEnum.MYPBM, AdminTypeEnum.APP, AdminTypeEnum.CLIENT].includes(adminType)) ?
            [{
              icon: 'dashboard--s',
              name: 'My Apps',
              routerLink: 'landing-page'
            }] : [],
          ...user.adminTypes.some(adminType =>
            [AdminTypeEnum.MYPBM, AdminTypeEnum.APP].includes(adminType)) ?
            [{
              icon: 'profile--s',
              name: 'User Management',
              routerLink: 'user-management/users'
            }, {
              icon: 'laptop--s',
              name: 'App Management',
              routerLink: 'app-management'
            }] : [],
          ...user.adminTypes.some(adminType =>
            [AdminTypeEnum.MYPBM, AdminTypeEnum.CLIENT].includes(adminType)) ?
            [{
              name: 'Client Management',
              icon: 'user-group--m',
              subNavItems: [
                {
                  name: 'Client Profile',
                  routerLink: 'client-management'
                },
                {
                  name: 'Client Group Management',
                  routerLink: 'client-group-management'
                }
              ]
            }] : [],
          ...user.adminTypes.some(adminType =>
            [AdminTypeEnum.MYPBM, AdminTypeEnum.APP, AdminTypeEnum.CLIENT].includes(adminType)) ?
            [{
              name: 'Report Management',
              icon: 'description--s',
              subNavItems: [
                {
                  name: 'Client Reports',
                  routerLink: 'report-management/clients-report'
                },
                {
                  name: 'User Reports',
                  routerLink: 'report-management/users-report'
                }
              ]
            }] : []
        ],
        iconMenu: true,
        menuState: MenuState.Closed
      };
    }
  }

  private displayPage() {
    this.appManagementService.getAssignedApps().subscribe(apps => {
      this.appManagementService.cachedGetAssignedApps.next(apps);
      if (this.currentUser.adminTypes.length > 0 || apps.length !== 1) {
        const cvsBase: HTMLElement = document.querySelector('cvs-base');
        cvsBase.style.visibility = 'visible';
      }
    });
  }

  logout = () => {
    this.currentUser = null;
    this.auditLogService.postAuditLog('logout', '');
    return new Observable((subscriber) => {
      this.currentUserService.logoutStop$.pipe(
        timeout({first: 400}),
        take(1)
      ).subscribe({
        next: (pending) => {
          this.unsavedChangesGuardService.openConfirmationDialog()
            .pipe(take(1))
            .subscribe((confirm) => {
              subscriber.next(true);
              subscriber.complete();
            });
        },
        error: (error) => {
          subscriber.next(true);
          subscriber.complete();
        }
      });
      this.currentUserService.logoutStart$.next(true);
    });
  };

  @HostListener('window:beforeunload')
  beforeunloadHandler() {
    if (this.currentUser) {
      this.auditLogService.postAuditLog('exitMyPBMLogin', '');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private loadMonitoringScripts() {
    if (null != environment.appDynamicsKey) {
      this.loadAppDynamicScript();
    }
    if (null != environment.adobeAnalyticsUrl) {
      this.loadAemScript();
    }
  }

  private loadAppDynamicScript() {
    const appDynamicsConfigScript = document.createElement('script');
    appDynamicsConfigScript.type = 'text/javascript';
    appDynamicsConfigScript.innerHTML =
      'window["adrum-start-time"] = new Date().getTime(); ' +
      '(function(config){ ' +
      'config.appKey = "' + environment.appDynamicsKey + '"; ' +
      'config.adrumExtUrlHttp = "http://cdn.appdynamics.com"; ' +
      'config.adrumExtUrlHttps = "https://cdn.appdynamics.com"; ' +
      'config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com"; ' +
      'config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com"; ' +
      'config.useHTTPSAlways = true; ' +
      'config.resTiming = {"bufSize": 200, "clearResTimingOnBeaconSend": true}; ' +
      'config.maxUrlLength = 512; ' +
      'config.enableCoreWebVitals = true; ' +
      'config.spa = {"spa2": true}; ' +
      '})(window["adrum-config"] || (window["adrum-config"] = {}));';
    const appDynamicsSrcScript = document.createElement('script');
    appDynamicsSrcScript.src = '//cdn.appdynamics.com/adrum/adrum-23.10.0.4353.js';
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(appDynamicsConfigScript);
      document.head.appendChild(appDynamicsSrcScript);
    }
  }

  private loadAemScript() {
    const aemScript = document.createElement('script');
    aemScript.src = environment.adobeAnalyticsUrl;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(aemScript);
    }

    aemScript.onload = () => {
      this.taggingService.analyticsScriptLoaded();
    };
  }
}
