import {HttpContext, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {
  constructor(private authenticationService: AuthenticationService) {}

    public buildSimpleHeader(params?) {
      const tokenHeader = this.getTokenHeader();
      if (tokenHeader) {
        return { headers: tokenHeader.append('Content-Type', 'application/json'), params };
      } else {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params };
      }
    }

    public buildSimpleCOHAPiHeader(params) {
      const tokenHeader = this.getTokenHeader();
      if (tokenHeader) {
        return { headers: tokenHeader.append('Content-Type', 'application/json')
            .append('appName','IAM')
            .append('transactionId', Date.now().toString()), params };
      } else {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            .append('appName','IAM')
            .append('transactionId', Date.now().toString()), params };
      }
    }

  public buildSimpleExcelUploadHeader(): {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  } {
    const tokenHeader = this.getTokenHeader();
    if (tokenHeader) {
      return {
        responseType: 'blob',
        headers: tokenHeader
      };
    }
  }

    public buildSimpleBlobHeader(params?) {
      const tokenHeader = this.getTokenHeader();
      if (tokenHeader) {
        return { headers: tokenHeader.append('Content-Type', 'application/json')
            .append('Timezone' , Intl.DateTimeFormat().resolvedOptions().timeZone),
          responseType: 'blob' as 'json', observe: 'response', params };
      } else {
        return {headers: new HttpHeaders({ 'Content-Type': 'application/json',
            Timezone : Intl.DateTimeFormat().resolvedOptions().timeZone }),
          responseType: 'blob' as 'json', observe: 'response', params
        };
      }
    }

    private getTokenHeader() {
      if (this.authenticationService) {
        return this.authenticationService.useAccessToken() ?
          this.authenticationService.getAccessTokenHeader() :
          this.authenticationService.getIdTokenHeader();
      } else {
        return null;
      }
    }

}
