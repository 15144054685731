<div style="width:100%;height:100%">
  <div class="back-button-container">
    <button id="back-button-add-edit-group"
            class="pds-btn-text-only back-button"
            aria-label="Back"
            (click)="exitAddEditGroup()">
      <mat-icon svgIcon="caret-left--s" class="back-button-icon"></mat-icon>
      Back
    </button>
  </div>
  <div id="addEditGroupBanner"></div>
  <h1 class="header-padding" *ngIf="mode === clientGroupModeEnum.ADD">Add Group</h1>
  <h1 class="header-padding" *ngIf="mode === clientGroupModeEnum.EDIT">Edit Group</h1>
  <p id="all-fields-required-header">All fields required unless marked as optional</p>
  <div class="tile__background-border">
    <cvs-loader-overlay-container [isLoading]="(showSpinner | async) ">
      <div class="client-management-header-container">
        <h2 class="sub-header-padding">Group Details</h2>
        <p *ngIf="isOnCopyMode()" class="copy-group-label">Copying from {{editOrCopyGroupData.groupName}} will include Client Group Type, Line of Business, all Clients, and all Users.</p>
      </div>
      <form [formGroup]="addGroupFormGroup" class="add-group__form" #ngForm="ngForm" (ngSubmit)=onSubmitForm()
            (keydown.enter)="$event.preventDefault()">
        <div class="add-group__form__group-type">
        <mat-form-field class="group-type-select">
          <mat-label aria-label="Client Group Type">Client Group Type</mat-label>
          <mat-select id="clientGroupTypeSelection"
                      formControlName="clientGroupType"
                      [disableOptionCentering]="true"
                      (selectionChange)="changeGroupType($event)">
            <mat-option *ngFor="let clientGroupOption of clientGroupOptions;"
                        [value]="clientGroupOption"
                        [disabled]="(isOnEditMode() || isOnCopyMode()) && clientGroupType.value !== null || undefined">
              {{clientGroupOption}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div class="access-type-container">
          <h3 class="access-type-header">User Access Type</h3>
          <ng-container formGroupName="clientGroupUserAccessTypes">
            <mat-checkbox [formControlName]="userType.value" class="accessType-{{userType.key}}"
                          [ngClass]="{'group-access-type-error' : !groupUserAccessTypes.valid && ngForm.submitted}"
                          *ngFor="let userType of UserAccessType | keyvalue: defaultEnumOrder">
              {{userType.value}}
            </mat-checkbox>
          </ng-container>
          <mat-error class="access-type-required-error" *ngIf="groupUserAccessTypes.errors?.['required'] && ngForm.submitted"
                     data-cy="access-type-required-error">
            <mat-icon class="access-type-error-icon" svgIcon="warning-f--xs"></mat-icon>
            User Access Type required
          </mat-error>
        </div>
        <div *ngIf="mode === clientGroupModeEnum.EDIT" class="owner-container">
            <div class="pds-label-text">Owner's Full Name</div>
            <div>{{editOrCopyGroupData.owner?.basicUserInfo.firstName}}  {{editOrCopyGroupData.owner?.basicUserInfo.lastName}}</div>
        </div>
        <div>
          <mat-form-field id=owner-email-field hideRequiredMarker="true">
            <input matInput placeholder="Owner's Email Address" formControlName="ownerEmailAddress" required
                   type="ownerEmail"
                   maxlength="60">
            <mat-error id="emailRequired" *ngIf="ownerEmail.errors?.['required']" data-cy="owner-email-required-error">
              <mat-icon class="add-group-email-error-icon" svgIcon="warning-f--xs"></mat-icon>
              Owner's Email Address required
            </mat-error>
            <mat-error *ngIf="ownerEmail.errors?.['ownerNotFound']" data-cy="owner-email-not-found-error">
              <mat-icon class="add-group-email-error-icon" svgIcon="warning-f--xs"></mat-icon>
              Owner's Email Address not found
            </mat-error>
            <mat-error *ngIf="ownerEmail.errors?.['invalidEmail']" data-cy="invalid-owner-address-error">
              <mat-icon class="add-group-email-error-icon" svgIcon="warning-f--xs"></mat-icon>
              Valid Email Address required
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field id=client-group-name-field hideRequiredMarker="true">
            <input matInput placeholder="Client Group Name" formControlName="clientGroupName" required
                   type="groupName"
                   maxlength="60">
            <mat-error *ngIf="groupName.errors?.['required']" data-cy="group-name-required-error">
              <mat-icon class="add-group-name-error-icon" svgIcon="warning-f--xs"></mat-icon>
              Client Group Name required
            </mat-error>
            <mat-error *ngIf="groupName.errors?.['duplicateGroupName']" data-cy="group-name-already-exist-error">
              <mat-icon class="add-group-name-error-icon" svgIcon="warning-f--xs"></mat-icon>
              Client Group already exists
            </mat-error>
          </mat-form-field>
        </div>
        <div class="client-group-description-container">
          <mat-form-field appearance="outline">
            <mat-label>Client Group Description</mat-label>
            <textarea matInput
                      maxlength="250"
                      formControlName="clientGroupDescription">
          </textarea>
            <mat-error class="add-edit-error" data-cy="group-description-required-error"
                       *ngIf="addGroupFormGroup.controls['clientGroupDescription'].errors?.required">
              <mat-icon svgIcon="warning-f--xs"></mat-icon>
              Client Group Description required
            </mat-error>
            <mat-hint>Max 250 Characters</mat-hint>
          </mat-form-field>
        </div>
        <div class="lob-container">
          <mat-form-field>
            <mat-select placeholder="Add Line Of Business"
                        formControlName="lineOfBusiness"
                        [disableOptionCentering]="true"
                        id="lobSelection" multiple>
              <div class="select-all-option" (click)="toggleAllSelection()">
                <mat-checkbox #selectAllCheckbox [indeterminate]="isIndeterminate()"
                              [checked]="areAllLineOfBusinessesChecked()"
                              (click)="$event.preventDefault()">
                  Select All
                </mat-checkbox>
              </div>
              <mat-option *ngFor="let lob of lobs" [value]="lob.id" (click)="toggleOption()">
                {{lob.name}}
              </mat-option>
            </mat-select>
            <mat-hint>Optional</mat-hint>
          </mat-form-field>
        </div>
        <div class="group-status-container" *ngIf="mode === clientGroupModeEnum.EDIT">
          <mat-label class="radio-label">Status</mat-label>
          <mat-radio-group formControlName="groupStatus" ngDefaultControl>
            <mat-radio-button class="radio-buttons" *ngFor="let option of statusOption; first as isPending"
                              [value]="option" [disabled]="isPending"
            >{{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <h2 class="tab-header-padding">Manage Clients and Users</h2>
        <mat-tab-group (selectedTabChange)="onTabChange($event)" class="pds-primary-tab" animationDuration="0ms" disableRipple color="primary">
          <mat-tab class="tab-label" label="Clients">
            <div class="client-search-container">
              <h2 aria-level="3" class="client-search-header">Client Search</h2>
              <p class="client-search-label">Search to add clients to this group, select the clients from the Search Results to add to the Selected Clients section.</p>
              <p>Clients may be removed from the group by selecting the client and saving the changes.</p>
            </div>

            <div class="client-search">
              <mat-form-field class="client-select">
                <mat-label aria-label ="Selected Client Option Field">Select Option</mat-label>
                <mat-select id="clientOption"  [disableOptionCentering]="true" [(value)]="clientSearchOption">
                  <ng-container *ngFor="let option of clientSearchOptions;">
                    <mat-option *ngIf="option.groupType === clientGroupType.value" [value]="option">
                      {{option.label}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>

              <app-client-search #clientSearch
                                 class="app-client-search-component"
                                 [buttonClass]="'pds-btn-ghost'"
                                 [clientSearchType]="clientSearchOption?.label"
                                 (searchOutput)="onSearch($event)"
                                 (validationError)="onClientSearchValidationError()">
              </app-client-search>
              <button *ngIf="isButtonShown"
                      id="clear-client-results"
                      aria-label="Clear Results Button"
                      mat-button color="primary"
                      type="button"
                      (click)="resetClientDualPicklist()">
                Clear Results
              </button>

            </div>
            <mat-error class="client-not-found-error" *ngIf="isClientNotFoundErrorShown" data-cy="client-not-found-error">
              <mat-icon class="error-icon client-not-found-error-icon" svgIcon="warning-f--xs"></mat-icon>
              <span class="client-not-found-error-message">No client(s) to display, search again or contact your client administrator to add the client.</span>
            </mat-error>
            <pds-dual-picklist [dataSource]="dataSource"
                               [selectedDataSource]="selectedDataSource"
                               [leftSelectionHeader]="'Search Results'"
                               [rightSelectionHeader]="'Selected Clients'"
                               [noResultsText]="'No Results'"
                               (rightDataUpdate)="onRightClientDataUpdate()"
                               (leftDataUpdate)="onLeftClientDataUpdate()"
            >
            </pds-dual-picklist>
          </mat-tab>

          <mat-tab class="tab-label" label="Users">
            <div class="tile__background-border user-search-container"
                 [ngClass]="{'user-search-container-margin-bottom': isOnAddMode()}">
              <h2 aria-level="3">User Search</h2>
              <p class="user-search-label" *ngIf="mode === clientGroupModeEnum.ADD">Search to add users to the group, then click on Add Group when completed.</p>
              <p class="user-search-label" *ngIf="mode === clientGroupModeEnum.EDIT">Search to add users to the group, then click on Save Changes when completed.</p>
              <div class="user-chips-container">
                <mat-form-field class="chip-list-field" appearance="standard">
                  <mat-label>Search</mat-label>
                  <mat-chip-list #chipList aria-label="User Selection">
                    <mat-chip *ngFor="let chipItem of userChipItems;"
                              [selectable]="true"
                              [disableRipple]="true"
                              (removed)="onUserChipRemove(chipItem)">
                      {{chipItem.basicUserInfo.lastName}}, {{chipItem.basicUserInfo.firstName}}
                      <button matChipRemove
                              [attr.aria-label]="'remove ' + chipItem.basicUserInfo.lastName + ', ' + chipItem.basicUserInfo.firstName"
                              (keydown.enter)="$event.preventDefault(); onUserChipRemove(chipItem)"
                              (keydown.space)="onUserChipRemove(chipItem)" class="removeChipBtn">
                        <mat-icon [svgIcon]="'close--xs'"></mat-icon>
                      </button>
                    </mat-chip>

                    <input #userChipInputRef
                           placeholder="User"
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="true"
                           [matAutocomplete]="auto"
                           [formControl]="usersSearchInput" />

                    <button matSuffix mat-icon-button
                            *ngIf="usersSearchInput.value"
                            (click)="onUserSearchTextClear($event)"
                            (keydown.enter)="onUserSearchTextClear($event)"
                            aria-hidden="false"
                            aria-label="Clear Search">
                      <mat-icon svgIcon="close-btn--s"></mat-icon>
                    </button>
                  </mat-chip-list>

                  <mat-autocomplete #auto (optionSelected)="onUserSelect($event)">
                    <mat-option *ngFor="let option of userOptSubj$ | async" [value]="option" id="user-option">
                      <span class="users-name-option-label">{{option.basicUserInfo.lastName}}, {{option.basicUserInfo.firstName}}</span>
                      <span class="users-email-option-label">{{option.basicUserInfo.email}}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint>Search by Last Name, First Name or Email Address</mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="tile__background-border user-access-container" *ngIf="isOnEditMode() || isOnCopyMode()">
              <h2 aria-level="3">Users in {{isOnEditMode() ? editOrCopyGroupData.groupName : 'Client Group'}}</h2>
              <ag-grid-angular
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     (gridReady)="onGridReady($event)"
                     [rowHeight]="32"
                     [context]="context"
                     [pagination]="true"
                     [suppressMenuHide]="true"
                     [rowSelection]="'multiple'"
                     [suppressRowClickSelection]="true"
                     (rowSelected)="onUserRowSelected()"
                     class="ag-theme-alpine user-access-grid">
              </ag-grid-angular>
              <app-paginator-rows-per-page
                [defaultPaginatorSize]="defaultPaginatorSize"
                [pageSizeOptions]="[25, 50, 100]"
                [gridApi]="gridApi"
                [columnApi]="columnApi">
              </app-paginator-rows-per-page>
            </div>
          </mat-tab>

        </mat-tab-group>

        <div class="add-edit-group-button-container">
          <button *ngIf="mode === clientGroupModeEnum.ADD" class="pds-btn-primary" type="submit">
            Add Group
          </button>
          <button *ngIf="mode === clientGroupModeEnum.EDIT" class="pds-btn-primary" type="submit">
            Save Changes
          </button>
          <button class="pds-btn-ghost" type="button" (click)="exitAddEditGroup()">
            Cancel
          </button>
        </div>

      </form>
    </cvs-loader-overlay-container>
  </div>
</div>
