<div class="back-button-container">
  <button id="back-button-app-dashboard"
          class="pds-btn-text-only back-button" (click)="onBack()">
    <mat-icon svgIcon="caret-left--s" class="back-button-icon"></mat-icon>
    Back
  </button>
</div>
<div id="appDashboardBanner"></div>
<div *ngIf="selectedApp">
  <h1 class="header-padding">{{selectedApp.appName}}</h1>
</div>

  <div class="content-card">
    <div class="tab-container">
      <cvs-loader-overlay-container  [isLoading]="(showSpinner | async)" [opacity]="1" loadingMessage="Loading">
      <mat-tab-group class="pds-primary-tab" animationDuration="0ms" disableRipple color="primary"
                     (selectedTabChange)="onTabChanged($event)"
                     [selectedIndex]="selectedTabIndex">
        <mat-tab class="tab-label" label="Roles">
          <ng-template matTabContent>
            <app-roles [selectedApp]="selectedApp"></app-roles>
          </ng-template>
        </mat-tab>

        <mat-tab class="tab-label" label="Permissions">
          <ng-template matTabContent>
            <app-permissions [selectedApp]="selectedApp"></app-permissions>
          </ng-template>
        </mat-tab>

        <mat-tab class="tab-label" label="Overview">
            <app-overview [appCode]="appCode" (appOverviewChange)='onAppOverviewChange($event)'
                          [tabChanged]="isTabClicked"></app-overview>
        </mat-tab>

      </mat-tab-group>
    </cvs-loader-overlay-container>
    </div>
  </div>
